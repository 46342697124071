import styles from "./AppLoading.module.css";
import MoonLoader from "react-spinners/MoonLoader";

export default function AppLoading() {
  return (
    <div className={styles.appLoading}>
      <MoonLoader color="var(--color-primary"></MoonLoader>
    </div>
  );
}
