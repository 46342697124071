import styles from "./CartSubmitSuccess.module.css";

export default function CartSubmitSuccess() {
  return (
    <div className={styles.body}>
      <div className={styles.submitSuccessBox}>
        <div className={styles.checkBox}>
          <img
            className={styles.check}
            src={require("../../../assets/Check.svg").default}
            alt="help"
          />
        </div>

        <h1>Order submitted</h1>
        <div className={styles.subtext}>
          <p>
            Before we can ship this order, there are additional tasks for you to
            complete. You can keep track of these action items on the{" "}
            <a href="/tasks">task list page</a>.
          </p>
        </div>
      </div>
    </div>
  );
}
