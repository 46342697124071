import { Patient, PatientFinancialData } from "../types/patient";
import { Prescription } from "../types/prescription";
import RecipientForm from "../types/recipient_form";
import instance from "./http_client";

export function getPatients() {
  return instance.get("/patients").then((response) => {
    return response.data.map(Patient.fromJson);
  });
}

// TODO: consolidate addPatient and updatePatient. Too much repeating.
export function addPatient(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string,
  allergies: string,
  dateOfBirth: string,
  weight: number,
  height: number,
  notes: string,
  icd10Codes: string,
  preferEmailCommunication: boolean,
  requireSignature: boolean,
  memberId: string,
  groupId: string
) {
  return instance
    .post("/patients/new", {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zip,
      allergies: allergies,
      date_of_birth: dateOfBirth,
      weight: weight,
      height: height,
      notes: notes,
      icd_10_codes: icd10Codes,
      prefer_email_communication: preferEmailCommunication,
      require_delivery_signature: requireSignature,
      member_id: memberId,
      group_id: groupId,
    })
    .then((response) => Patient.fromJson(response.data));
}

export function updatePatient(
  pk: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string,
  allergies: string,
  dateOfBirth: string,
  weight: number,
  height: number,
  notes: string,
  icd10Codes: string,
  preferEmailCommunication: boolean,
  requireSignature: boolean,
  memberId: string,
  groupId: string
) {
  return instance
    .patch(`/patients/${pk}/update`, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zip,
      allergies: allergies,
      date_of_birth: dateOfBirth,
      weight: weight,
      height: height,
      notes: notes,
      icd_10_codes: icd10Codes,
      prefer_email_communication: preferEmailCommunication,
      require_delivery_signature: requireSignature,
      member_id: memberId,
      group_id: groupId,
    })
    .then((response) => Patient.fromJson(response.data));
}

export function deletePatient(pk: number) {
  return instance.patch(`/patients/${pk}/deactivate`);
}

export function getRecipientForms(patientPk: number) {
  return instance
    .get(`/patients/${patientPk}/forms`)
    .then((res) => res.data.map(RecipientForm.fromJson));
}

export function archivePrescription(
  prescriptionPk: number
): Promise<Prescription> {
  return instance
    .patch(`/patients/prescriptions/${prescriptionPk}/update`, {
      archived: true,
    })
    .then((res) => Prescription.fromJson(res.data));
}

export function getPatientsWithOutstandingForms(): Promise<Patient[]> {
  return instance
    .get("/patients/with-outstanding-forms")
    .then((res) => res.data.map(Patient.fromJson));
}

export function getUntransferredPrescriptions(): Promise<Prescription[]> {
  return instance
    .get("/patients/untransferred-prescriptions")
    .then((res) => res.data.map(Prescription.fromJson));
}

export function createPatientFinancialData(
  patientPk: number,
  householdSize: number,
  estimatedIncome: number,
  medicationsUnaffordable: boolean,
  medsNotCovered: boolean
) {
  return instance
    .post("/patients/finances/new", {
      household_size: householdSize,
      estimated_income: estimatedIncome,
      difficulty_affording_expenses: medicationsUnaffordable,
      meds_not_covered: medsNotCovered,
      patient: patientPk,
    })
    .then((res) => PatientFinancialData.fromJson(res.data));
}

export function updatePatientFinancialData(
  pk: number,
  householdSize: number,
  estimatedIncome: number,
  medicationsUnaffordable: boolean,
  medsNotCovered: boolean
) {
  return instance
    .patch(`/patients/finances/${pk}/update`, {
      household_size: householdSize,
      estimated_income: estimatedIncome,
      difficulty_affording_expenses: medicationsUnaffordable,
      meds_not_covered: medsNotCovered,
    })
    .then((res) => PatientFinancialData.fromJson(res.data));
}
