import { Patient } from "../../../types/patient";
import PatientListItem from "./PatientListItem";

interface PatientListProps {
  patients: Patient[];
  onEdit: (patient: Patient) => void;
  onDelete: (patient: Patient) => void;
}

export default function PatientList(props: PatientListProps) {
  return (
    <>
      {props.patients.map((p) => (
        <PatientListItem
          key={p.pk}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
          patient={p}
        ></PatientListItem>
      ))}
    </>
  );
}
