import { useEffect, useState } from "react";
import Overlay from "../../../components/Overlay/Overlay";
import { getRecipientForms } from "../../../api/patients_network_api";
import { ApiStatus } from "../../../types/api_status";
import RecipientForm from "../../../types/recipient_form";
import { Patient } from "../../../types/patient";
import BeatLoader from "react-spinners/BeatLoader";
import Card from "../../../components/Card/Card";
import X from "../../../assets/X.svg";
import View from "../../../assets/View.svg";
import styles from "./PatientDocuments.module.css";

interface PatientDocumentsState {
  status: ApiStatus;
  recipientForms: RecipientForm[] | null;
}

export default function PatientDocuments(props: {
  patient: Patient;
  onExit: () => void;
}) {
  const [state, setState] = useState<PatientDocumentsState>({
    status: ApiStatus.LOADING,
    recipientForms: null,
  });

  useEffect(() => {
    getRecipientForms(props.patient.pk)
      .then((forms) => {
        setState(() => ({
          status: ApiStatus.SUCCESS,
          recipientForms: forms,
        }));
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, status: ApiStatus.FAILURE }));
      });
  }, [props.patient.pk]);

  return (
    <Overlay>
      <Card className={styles.card}>
        <h2>Signed documents</h2>
        <p className={styles.subtitle}>
          View {props.patient.fullName}'s signed recipient forms.
        </p>
        <button type="button" className={styles.exit} onClick={props.onExit}>
          <img src={X} alt="exit" />
        </button>
        {state.recipientForms !== null ? (
          state.recipientForms.filter((f) => f.file !== null).length === 0 ? (
            <div className={styles.fallbackBody}>
              <p className={styles.emptyText}>
                {props.patient.fullName} hasn't signed any forms yet
              </p>
            </div>
          ) : (
            <div className={styles.documents}>
              {state.recipientForms.map((f) => (
                <div className={styles.document}>
                  {props.patient.fullName}, {f.createdAt.toDateString()}{" "}
                  <a
                    href={f.file}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.view}
                  >
                    <img src={View} alt="view"></img>
                  </a>
                </div>
              ))}
            </div>
          )
        ) : state.status === ApiStatus.FAILURE ? (
          <div className={styles.fallbackBody}>
            <p className={styles.errorText}>Error loading documents</p>
          </div>
        ) : (
          <div className={styles.fallbackBody}>
            <BeatLoader color={"var(--color-primary)"} size={15}></BeatLoader>
          </div>
        )}{" "}
      </Card>
    </Overlay>
  );
}
