import { Patient } from "../../types/patient";
import Overlay from "../Overlay/Overlay";
import PatientForm from "./PatientForm";

interface PatientFormOverlayProps {
  onDone: (patient: Patient | null) => void;
  editingPatient?: Patient;
}

export default function PatientFormOverlay(props: PatientFormOverlayProps) {
  return (
    <Overlay>
      <PatientForm {...props}></PatientForm>
    </Overlay>
  );
}
