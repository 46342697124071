import styles from "./InventoryList.module.css";
import "react-toastify/dist/ReactToastify.css";
import { Medication } from "../../../types/medication";
import MedicationDetail from "../../../components/MedicationDetail/MedicationDetail";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import { toast } from "react-toastify";

interface InventoryListItemProps {
  medication: Medication;
  index: number;
}

export default function InventoryListItem(props: InventoryListItemProps) {
  const notify = () =>
    toast.success(
      <p>
        Added to order{" "}
        <span className={styles.link}>
          <a href="viewOrder">Go to order</a>
        </span>
      </p>
    );

  const m = props.medication;

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const showDetail = () => {
    setGlobalOverlay(
      <MedicationDetail
        medication={m}
        onExit={(cartItemCreated: boolean) => {
          hideGlobalOverlay();
          if (cartItemCreated) {
            notify();
          }
        }}
      ></MedicationDetail>
    );
  };

  return (
    <div
      className={`${styles.inventoryListItem} ${
        props.index % 2 === 0 ? styles.shaded : ""
      }`}
    >
      <div className={styles.nameWrap}>
        <p className={styles.name} onClick={showDetail}>
          {m.displayName} <span className={styles.strength}> {m.strength}</span>
        </p>
      </div>
      <p className={styles.stock}>
        {props.medication.availableStock} {props.medication.formulation}{" "}
        available
      </p>
      <div className={styles.buttonWrap}>
        <button className={styles.selectButton} onClick={showDetail}>
          Select
        </button>
      </div>
    </div>
  );
}
