import { ReactElement, useEffect, useState } from "react";
import { Medication } from "../../types/medication";
import FeeBreakdown from "../FeeBreakdown/FeeBreakdown";
import styles from "./MedicationDetail.module.css";
import PatientForm from "../PatientForm/PatientForm";
import { FormProvider, useForm } from "react-hook-form";
import { ApiStatus } from "../../types/api_status";
import AddToCartButton from "../AddToCartButton/AddToCartButton";
import { Patient } from "../../types/patient";
import { getPatients } from "../../api/patients_network_api";
import {
  addToCart,
  verifyCanAllocateCartItem,
} from "../../api/cart_network_api";
import { useAuth } from "../../providers/AuthUserProvider";
import { assert } from "../../utils";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";

interface MedicationDetailProps {
  medication: Medication;
  onExit: (created: boolean) => void;
}

interface MedicationDetailState {
  showAddPatient: boolean;
  addToCartStatus: ApiStatus;
  addToCartNetworkErrorMessage: string;
  patientsFetchStatus: ApiStatus;
  verificationStatus: ApiStatus;
  patients: Patient[] | null;
  showAllocationWarning: boolean;
  numAllocationRefills: number;
}

interface FormInput {
  patientPk: number;
  quantity: number | string;
  customQuantity: number;
  remainingRefills: number;
  dueDate: string;
  daysSupply: number;
  previouslyFilled: boolean;
}

const getLocalizedTodayString = () => {
  const dateArray = new Date().toLocaleDateString().split("/");
  const [month, day, year] = dateArray;
  return `${year}-${month}-${day}`;
};

export default function MedicationDetail(props: MedicationDetailProps) {
  const [state, setState] = useState<MedicationDetailState>({
    showAddPatient: false,
    addToCartStatus: ApiStatus.INITIAL,
    verificationStatus: ApiStatus.INITIAL,
    addToCartNetworkErrorMessage: "",
    patientsFetchStatus: ApiStatus.INITIAL,
    showAllocationWarning: false,
    numAllocationRefills: 0,
    patients: null,
  });

  const updateState = (data: Partial<MedicationDetailState>) => {
    setState((prev) => ({ ...prev, ...data }));
  };

  const methods = useForm<FormInput>({ mode: "onSubmit" });

  const { user, updateUser } = useAuth();

  useEffect(() => {
    getPatients()
      .then((patients) =>
        updateState({
          patients: patients,
          patientsFetchStatus: ApiStatus.SUCCESS,
        })
      )
      .catch(() =>
        updateState({
          patientsFetchStatus: ApiStatus.FAILURE,
        })
      );
  }, []);

  const getQuantityOptions = () => {
    const available = props.medication.availableStock;
    const options: ReactElement[] = [];
    const count = Math.floor(available / props.medication.avgQtyPerScript);
    if (count === 0) {
      return (
        <option
          value={available}
        >{`${available} ${props.medication.formulation}`}</option>
      );
    }
    for (let i = 1; i <= count; i++) {
      const value = i * props.medication.avgQtyPerScript;
      options.push(
        <option
          value={value}
        >{`${value} ${props.medication.formulation}`}</option>
      );
    }
    return options;
  };

  const handleAddPatientResult = (patient: Patient | null) => {
    if (!patient) {
      updateState({
        showAddPatient: false,
      });
      return;
    }

    updateState({
      patients: [...(state?.patients ?? []), patient],
      showAddPatient: false,
    });

    methods.setValue("patientPk", patient.pk);
    methods.clearErrors("patientPk");
  };

  const onSubmit = async (data: FormInput) => {
    const quantity =
      data.quantity === "custom" ? data.customQuantity : +data.quantity;

    let verifyResult;
    try {
      verifyResult = await verifyCanAllocateCartItem(
        props.medication.pk,
        quantity,
        data.remainingRefills,
        data.dueDate,
        data.previouslyFilled,
        data.daysSupply
      );
    } catch (e) {
      updateState({
        addToCartStatus: ApiStatus.FAILURE,
        addToCartNetworkErrorMessage: `Error verifying inventory.`,
      });
      return;
    }

    if (verifyResult.canAllocate) {
      await submit(
        data.patientPk,
        quantity,
        data.remainingRefills,
        data.dueDate,
        data.previouslyFilled,
        data.daysSupply
      );
    } else if (verifyResult.fillsAvailable === 0) {
      updateState({
        addToCartStatus: ApiStatus.FAILURE,
        addToCartNetworkErrorMessage: `Not enough ${props.medication.displayName} ${props.medication.strength} in stock.`,
      });
    } else {
      updateState({
        showAllocationWarning: true,
        numAllocationRefills:
          verifyResult.fillsAvailable -
          (methods.getValues().previouslyFilled ? 0 : 1),
      });
    }
  };

  const submit = (
    patientPk: number,
    quantity: number,
    remainingRefills: number,
    dueDate: string,
    previouslyFilled: boolean,
    daysSupply: number
  ) => {
    updateState({ addToCartStatus: ApiStatus.LOADING });
    return addToCart(
      props.medication.pk,
      patientPk,
      quantity,
      remainingRefills,
      dueDate,
      previouslyFilled,
      daysSupply
    )
      .then(() => {
        updateState({ addToCartStatus: ApiStatus.SUCCESS });
        assert(user);
        updateUser({ ...user, numCartItems: user.numCartItems + 1 });
        props.onExit(true);
      })
      .catch((error) => {
        // const errorMessage = getAddToCartErrorMessage(error);
        updateState({
          addToCartStatus: ApiStatus.FAILURE,
          addToCartNetworkErrorMessage: "Error reserving medication",
        });
      });
  };

  const quantity = methods.watch("quantity");
  const showCustomQuantity = quantity === "custom";
  const customQuantity = methods.watch("customQuantity");

  const selectQuantityError = methods.formState.errors.quantity;
  const customQuantityError = methods.formState.errors.customQuantity;
  const selectPatientError = methods.formState.errors.patientPk;
  const remainingRefillsError = methods.formState.errors.remainingRefills;

  return (
    <div className={styles.detail}>
      {state.showAddPatient ? (
        <PatientForm onDone={handleAddPatientResult}></PatientForm>
      ) : state.showAllocationWarning ? (
        <ConfirmationDialog
          title={"Warning"}
          onConfirm={() => {
            if (state.verificationStatus === ApiStatus.LOADING) {
              return;
            }
            const quantity =
              methods.getValues().quantity === "custom"
                ? methods.getValues().customQuantity
                : +methods.getValues().quantity;
            submit(
              methods.getValues().patientPk,
              quantity,
              state.numAllocationRefills,
              methods.getValues().dueDate,
              methods.getValues().previouslyFilled,
              methods.getValues().daysSupply
            );
          }}
          onCancel={() => updateState({ showAllocationWarning: false })}
          subtitle={`We only have enough stock of ${
            props.medication.displayName
          } ${props.medication.strength} to fulfill ${
            methods.getValues().previouslyFilled ? "" : "this order and"
          } ${
            state.numAllocationRefills
          } refills. Would you like to reserve this amount instead?`}
        ></ConfirmationDialog>
      ) : (
        <FormProvider {...methods}>
          <form
            className={styles.card}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <button
              type="button"
              className={styles.exit}
              onClick={() => props.onExit(false)}
            >
              <img src={require("../../assets/X.svg").default} alt="exit" />
            </button>
            <div className={styles.content}>
              <div className={styles.data}>
                <p className={styles.name}>
                  {props.medication.displayName} {props.medication.strength}
                </p>
                <p className={styles.genericName}>
                  {props.medication.genericName}
                </p>
              </div>
            </div>
            <FeeBreakdown
              condensed={true}
              title="Service Fees"
              tierName={props.medication.pricingTier.name}
              adminFee={props.medication.pricingTier.adminFee}
              sourcingFee={props.medication.pricingTier.sourcingFee}
              handlingFee={props.medication.pricingTier.handlingFee}
              multiplier={
                (showCustomQuantity
                  ? !customQuantity
                    ? props.medication.avgQtyPerScript
                    : Math.min(
                        props.medication.availableStock,
                        Math.max(customQuantity, 0)
                      )
                  : quantity === undefined
                  ? props.medication.avgQtyPerScript
                  : +quantity) / props.medication.avgQtyPerScript
              }
              quantity={props.medication.avgQtyPerScript}
              formulation={props.medication.formulation}
              className={styles.medicationFees}
            ></FeeBreakdown>
            <div className={styles.selectorWrap}>
              <div className={styles.selectors}>
                <div>
                  <p className={styles.label}>Recipient patient</p>
                  <select
                    {...methods.register("patientPk", { required: true })}
                    className={`${styles.select} ${
                      selectPatientError ? styles.selectError : ""
                    }`}
                    onChange={(event) => {
                      const val = event.target.value;
                      if (val === "add") {
                        updateState({ showAddPatient: true });
                      } else {
                        methods.setValue("patientPk", +val);
                        methods.clearErrors("patientPk");
                      }
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Select patient
                    </option>
                    <option value="add">+ Add patient</option>
                    {state.patients
                      ? state.patients.map((p) => (
                          <option value={p.pk}>{p.fullName}</option>
                        ))
                      : []}
                  </select>
                </div>
                <div>
                  <p className={styles.label}>
                    Quantity per fill ({props.medication.availableStock} total{" "}
                    {props.medication.formulation} available)
                  </p>
                  <div className={styles.quantitySelectors}>
                    <select
                      {...methods.register("quantity", { required: true })}
                      className={`${styles.select} ${
                        selectQuantityError ? styles.selectError : ""
                      }`}
                      onChange={(event) => {
                        methods.setValue("quantity", event.target.value);
                        methods.resetField("customQuantity");
                      }}
                    >
                      {getQuantityOptions()}
                      <option value="custom">Custom quantity...</option>
                    </select>

                    {showCustomQuantity && (
                      <input
                        {...methods.register("customQuantity", {
                          required: true,
                        })}
                        type="number"
                        max={props.medication.availableStock}
                        min={1}
                        defaultValue={""}
                        className={`${styles.customQuantityInput} ${
                          customQuantityError ? styles.inputError : ""
                        }`}
                        placeholder={`# of ${props.medication.formulation}`}
                      ></input>
                    )}
                  </div>
                </div>{" "}
                <div>
                  <p className={styles.label}>Remaining refills</p>
                  <input
                    {...methods.register("remainingRefills", {
                      required: true,
                    })}
                    type="number"
                    placeholder="Number of refills remaining"
                    max={100}
                    min={0}
                    className={`${styles.customQuantityInput} ${
                      remainingRefillsError ? styles.inputError : ""
                    }`}
                  ></input>
                </div>
                <div className={styles.flexControls}>
                  <div>
                    <p className={styles.label}>Due date</p>
                    <input
                      {...methods.register("dueDate", {
                        required: true,
                      })}
                      type="date"
                      min={getLocalizedTodayString()}
                      placeholder="Due date"
                      className={`${styles.dueDateInput} ${
                        methods.formState.errors.dueDate
                          ? styles.dueDateError
                          : ""
                      }`}
                    ></input>
                  </div>
                  <div>
                    <p className={styles.label}>Days supply</p>
                    <input
                      {...methods.register("daysSupply", {
                        valueAsNumber: true,
                        required: true,
                      })}
                      type="number"
                      placeholder="Number of days supply"
                      min={0}
                      className={`${styles.customQuantityInput} ${
                        methods.formState.errors.daysSupply
                          ? styles.inputError
                          : ""
                      }`}
                    ></input>
                  </div>
                </div>
                <div className={styles.previouslyFilledContainer}>
                  <input
                    {...methods.register("previouslyFilled")}
                    className={styles.signatureCheckbox}
                    type="checkbox"
                    id="previouslyFilled"
                  ></input>
                  <label
                    className={styles.signatureLabel}
                    htmlFor="previouslyFilled"
                  >
                    This prescription was previously filled at another pharmacy
                  </label>
                </div>
              </div>
              {!methods.formState.isValid && methods.formState.isSubmitted && (
                <p className={styles.error}>Please correct errors</p>
              )}
              {state.addToCartNetworkErrorMessage && (
                <p className={styles.error}>
                  {state.addToCartNetworkErrorMessage}
                </p>
              )}
              <div className={styles.buttonWrap}>
                <AddToCartButton
                  disabled={
                    state.addToCartStatus === ApiStatus.LOADING ||
                    state.verificationStatus === ApiStatus.LOADING
                  }
                  submit={true}
                  primary={true}
                  width="100%"
                  padding="8px"
                ></AddToCartButton>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
}
