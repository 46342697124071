import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import Search from "../../components/Search/Search";
import { ApiStatus } from "../../types/api_status";
import { Order } from "../../types/order";
import { useEffect, useState } from "react";
import { getOrders } from "../../api/orders_network_api";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import OrderHistoryItem from "./OrderHistoryList/OrderHistoryItem";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";
import { useNavigate } from "react-router";

interface OrderHistoryState {
  status: ApiStatus;
  orders: Order[] | null;
  filter: string;
}

const filterOrders = (filter: string, orders: Order[]) => {
  const pattern = filter.toUpperCase();
  return orders.filter(
    (o) =>
      o.patient.fullName.toUpperCase().includes(pattern) ||
      o.patient.displayAddress.toUpperCase().includes(pattern)
  );
};

export default function OrderHistory() {
  const navigate = useNavigate();

  const [state, setState] = useState<OrderHistoryState>({
    status: ApiStatus.INITIAL,
    orders: null,
    filter: "",
  });

  useEffect(() => {
    getOrders()
      .then((orders) => {
        setState((prev) => ({
          ...prev,
          orders: orders,
          status: ApiStatus.SUCCESS,
        }));
      })
      .catch((e) => {
        setState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }));
      });
  }, []);

  return (
    <DashboardPageBody
      title="Order history"
      headerSuffix={
        <Search
          placeholder="Search orders"
          onChange={(val) => setState((prev) => ({ ...prev, filter: val }))}
        ></Search>
      }
    >
      {state.orders !== null ? (
        state.orders.length > 0 ? (
          <>
            {filterOrders(state.filter, state.orders).map((order, index) => (
              <OrderHistoryItem
                key={order.pk}
                onViewConsentCode={() =>
                  setState((prev) => ({
                    ...prev,
                    displayedOrderConsentInfo: order,
                  }))
                }
                order={order}
              ></OrderHistoryItem>
            ))}
          </>
        ) : (
          <DashboardPageEmptyState
            title="You haven't made any orders"
            subtitle="Get started by browsing our inventory."
            actionLabel="Go to inventory"
            onClickAction={() => navigate("/inventory")}
          ></DashboardPageEmptyState>
        )
      ) : state.status === ApiStatus.FAILURE ? (
        <DashboardPageFailState
          errorMessage="Unable to get order history"
          subtitle="Please try reloading."
        />
      ) : (
        <DashboardPageLoadState></DashboardPageLoadState>
      )}
    </DashboardPageBody>
  );
}
