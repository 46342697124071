import Button from "./Button";
import styles from "./Button.module.css";

interface PlusButtonProps {
  onClick: () => void;
  label: string;
  pill?: boolean;
  padding?: string;
}
export default function PlusButton(props: PlusButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      pill={props.pill}
      padding={props.padding ?? "8px 24px"}
    >
      <div className={styles.plusWrap}>
        <svg
          className={styles.plus}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M6 12H18M12 6V18"
              stroke="#ffffff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
        {props.label}
      </div>
    </Button>
  );
}
