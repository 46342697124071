import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import FaqItem from "./FaqItem";
import styles from "./Faq.module.css";
import {
  ADMIN_FEE_EXPLAINER,
  HANDLING_FEE_EXPLAINER,
  SOURCING_FEE_EXPLAINER,
} from "../../constants";

const NON_TARGET_TIERS = [
  {
    name: "Copper",
    description:
      "Medications that are in the “Copper” tier are not targeted by MediCircle. Because of how low their demand is, MediCircle will not accept a donation of Copper medications alone. However, Copper medications may be reclaimed alongside target medications. The only Service Fee incurred when reserving a Copper medication is the Handling Fee - the Admin fee is waived, and there is no Sourcing Fee.",
  },
  {
    name: "Steel",
    description:
      "“Steel” tier medications are not targeted by MediCircle. However, MediCircle will accept a donation comprised of only Steel medications. While there is no Sourcing Fee, Steel medications will have an associated Handling and Admin Fee.",
  },
];
const TARGET_TIERS = [
  {
    name: "Bronze",
    description:
      "Medications in the “Bronze” tier are actively targeted by MediCircle. Bronze medications are not difficult to source and have moderate demand from patients, providers, and health systems. Bronze tier medications have an associated Sourcing Fee of $150. ",
  },
  {
    name: "Silver",
    description:
      "Medications in the “Silver” tier are actively targeted by MediCircle. Silver medications are of moderate difficulty to source and have moderate demand from patients, providers, and health systems. Silver tier medications have an associated Sourcing Fee of $650. ",
  },
  {
    name: "Gold",
    description:
      "Medications in the “Gold” tier are actively targeted by MediCircle. Gold medications are very difficult to source and have moderate demand from patients, providers, and health systems. Gold tier medications have an associated Sourcing Fee of $1250.",
  },
  {
    name: "Ruby",
    description:
      "Medications in the “Ruby” tier are actively targeted by MediCircle. Ruby medications are not difficult to source and have high demand from patients, providers, and health systems. MediCircle allocates a fair amount of resources to obtain these medications. Ruby tier medications have an associated Sourcing Fee of $2250.",
  },
  {
    name: "Sapphire",
    description:
      "Medications in the “Sapphire” tier are actively targeted by MediCircle. Sapphire medications are of moderate difficulty to source and have high demand from patients, providers, and health systems. Sapphire tier medications have an associated Sourcing Fee of $2750.",
  },
  {
    name: "Emerald",
    description:
      "Medications in the “Emerald” tier are actively targeted by MediCircle. Emerald medications are very difficult to source and have high demand from patients, providers, and health systems. Emerald tier medications have an associated Sourcing Fee of $3250. ",
  },
  {
    name: "Diamond",
    description:
      "Medications in the “Diamond” tier are actively targeted by MediCircle. Diamond medications are not difficult to source and have very high demand from patients, providers, and health systems. Diamond medications have an associated Sourcing Fee of $5500.",
  },
  {
    name: "Pearl",
    description:
      "Medications in the “Pearl” tier are actively targeted by MediCircle. Pearl medications are of moderate difficulty to source and have very high demand from patients, providers, and health systems. Pearl tier medications have an associated Sourcing Fee of $6000.",
  },
  {
    name: "Platinum",
    description:
      "Medications in the “Platinum” tier are actively targeted by MediCircle. Platinum medications are very difficult to source and have high demand from patients, providers, and health systems. Platinum tier medications have an associated Sourcing Fee of $6500.",
  },
];

export default function Faq() {
  return (
    <DashboardPageBody title={"FAQ"}>
      <p className={styles.subtitle}>
        Please refer to the information below for answers to frequently asked
        questions. If you have a question which is not answered here, please{" "}
        <a href="support">contact support.</a>
      </p>
      <FaqItem title="How much does MediCircle cost?">
        <>
          <p>
            MediCircle does not participate in the resale of medications or
            submit claims for reimbursement. The resale of medications is
            strictly prohibited.
          </p>
          <p>
            Instead, MediCircle charges Service Fees for the added value that
            MediCircle provides patients, payers, providers, and health systems.
            MediCircle’s Service Fees include the Handling Fee, the Admin Fee,
            and the Sourcing Fee.
          </p>
        </>
      </FaqItem>
      <FaqItem title="What is the Handling Fee?">
        <p>{HANDLING_FEE_EXPLAINER}</p>
      </FaqItem>
      <FaqItem title="What is the Admin Fee?">
        <p>{ADMIN_FEE_EXPLAINER}</p>
      </FaqItem>
      <FaqItem title="What is the Sourcing Fee?">
        <p>{SOURCING_FEE_EXPLAINER}</p>
      </FaqItem>
      <FaqItem title="What are the Tiers?">
        <>
          <p>
            MediCircle categorizes each dose of a medication into a tier based
            on the amount of resources necessary to procure the supply for a
            prescription. The tiers are determined by how difficult a medication
            is to source and the demand for the medication by patients,
            providers, and health systems.
          </p>
          <div className={styles.tierCategory}>
            <p className={styles.tierHeading}>NON-TARGET TIERS</p>
            <div className={styles.tierList}>
              {NON_TARGET_TIERS.map((t) => (
                <p className={styles.tier}>
                  <span className={styles.tierName}>{t.name}</span>:{" "}
                  {t.description}
                </p>
              ))}
            </div>
          </div>
          <div className={styles.tierCategory}>
            <p className={styles.tierHeading}>TARGET TIERS</p>
            <div className={styles.tierList}>
              {TARGET_TIERS.map((t) => (
                <p className={styles.tier}>
                  <span className={styles.tierName}>{t.name}</span>:{" "}
                  {t.description}
                </p>
              ))}
            </div>
          </div>
        </>
      </FaqItem>
      <FaqItem title="How do you determine a medication’s tier?">
        <p>A medication’s tier is determined by two factors:</p>
        <ol className={styles.tierFactorList}>
          <li>
            <span className={styles.tierName}>Need</span>: Additional resources
            are dedicated to sourcing medications that have higher demonstrated
            need from patients and providers. By doing so, MediCircle collects
            more medications that people need, maximizing our ability to
            positively impact the health system.
          </li>
          <li>
            <span className={styles.tierName}>Sourcing Difficulty</span>:
            Certain medications are harder to source, necessitating the devotion
            of additional resources to procure adequate supply.
          </li>
        </ol>
      </FaqItem>
    </DashboardPageBody>
  );
}
