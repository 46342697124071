import { ToastContainer } from "react-toastify";
import styles from "./MyToastContainer.module.css";

export default function MyToastContainer() {
  return (
    <ToastContainer
      toastClassName={styles.toast}
      position="bottom-right"
      hideProgressBar={true}
      autoClose={2000}
    />
  );
}
