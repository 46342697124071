import { Medication, MedicationJson } from "./medication";
import { Patient, PatientJson } from "./patient";

export enum OrderStatus {
  ActionRequired,
  PreparingForShipment,
  Shipped,
  Delivered,
  Canceled,
}

export interface OrderJson {
  id: number;
  patient: PatientJson;
  status: string;
  created_at: string;
  requests: string[];
  total_fees: number;
  tracking_number: string;
  order_items: OrderItemJson[];
  prescriptions: string[];
}

export interface OrderItemJson {
  id: number;
  medication: MedicationJson;
  quantity: number;
  consent_signed: boolean;
  prescription_received: boolean;
  remaining_refills: number;
}

const getStatus = (data: string) => {
  switch (data) {
    case "SH":
      return OrderStatus.Shipped;
    case "DE":
      return OrderStatus.Delivered;
    case "CA":
      return OrderStatus.Canceled;
    case "PS":
      return OrderStatus.PreparingForShipment;
    case "AR":
    default:
      return OrderStatus.ActionRequired;
  }
};

export class OrderItem {
  constructor(
    public pk: number,
    public medication: Medication,
    public quantity: number,
    public consentSigned: boolean,
    public prescriptionReceived: boolean,
    public remainingRefills: number
  ) {}
  static fromJson(data: OrderItemJson): OrderItem {
    return new OrderItem(
      data.id,
      Medication.fromJson(data.medication),
      data.quantity,
      data.consent_signed,
      data.prescription_received,
      data.remaining_refills
    );
  }
}

export class Order {
  constructor(
    public pk: number,
    public patient: Patient,
    public status: OrderStatus,
    public createdAt: Date,
    public orderItems: OrderItem[],
    public prescriptions: string[],
    public totalFees: number,
    public trackingNumber: string
  ) {}

  getPrettyDate() {
    const date = this.createdAt;
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  }

  static fromJson(data: OrderJson): Order {
    return new Order(
      data.id,
      Patient.fromJson(data.patient),
      getStatus(data.status),
      new Date(data.created_at),
      data.order_items.map(OrderItem.fromJson),
      data.prescriptions,
      data.total_fees,
      data.tracking_number
    );
  }
}
