import { Patient } from "../../../types/patient";
import styles from "./PatientPrescriptions.module.css";
import PatientPrescriptionItem from "./PatientPrescriptionItem";

export default function PatientPrescriptions(props: { patient: Patient }) {
  return (
    <div className={styles.prescriptions}>
      <table className={styles.prescriptionTable}>
        <tr>
          <th>Prescription</th>
          <th>Due date</th>
          <th>Remaining refills</th>
          <th>Received prescription</th>
          <th>Patient forms signed</th>
          <th></th>
        </tr>
        {props.patient.prescriptions.map((p) => (
          <PatientPrescriptionItem
            patient={props.patient}
            prescription={p}
          ></PatientPrescriptionItem>
        ))}
      </table>
    </div>
  );
}
