import styles from "./Card.module.css";

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export default function Card(props: CardProps) {
  return (
    <div className={`${styles.card} ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
}
