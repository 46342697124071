import React, { forwardRef } from "react";
import styles from "./MoreOptions.module.css";

interface MoreOptionsProps {
  children: React.ReactNode;
}

const MoreOptions = forwardRef<HTMLDivElement, MoreOptionsProps>(
  function MoreOptions(props, ref) {
    return (
      <div ref={ref} className={styles.moreOptions}>
        {props.children}
      </div>
    );
  }
);

export default MoreOptions;
