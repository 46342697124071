import styles from "./SidebarNavItem.module.css";

interface SidebarNavItemProps {
  active: boolean;
  label: string;
  onClick: () => void;
  iconPath?: string;
  suffix?: React.ReactNode;
}

export default function SidebarNavItem(props: SidebarNavItemProps) {
  return (
    <button
      onClick={props.onClick}
      className={`${styles.sidebarNavItem} ${
        props.active ? styles.active : ""
      }`}
    >
      <div>
        {props.iconPath && (
          <img className={styles.icon} src={props.iconPath} alt={""}></img>
        )}
        {props.label}
      </div>
      {props.suffix}
    </button>
  );
}
