import { BrowserRouter as Router } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import AuthUserProvider from "./providers/AuthUserProvider";
import Sidebar from "./components/Sidebar/Sidebar";
import styles from "./App.module.css";
import MyRoutes from "./components/MyRoutes/MyRoutes";
import GlobalOverlayProvider from "./providers/GlobalOverlayProvider";
import MyToastContainer from "./components/Toast/MyToastContainer";

function App() {
  useEffect(() => {
    document.title = "MediCircle";
  }, []);

  return isMobile ? (
    <div className={styles.mobile}>Please view on a computer.</div>
  ) : (
    <Router>
      <AuthUserProvider>
        <GlobalOverlayProvider>
          <div className={styles.app}>
            <Sidebar />
            <div className={styles.content}>
              <MyRoutes />
            </div>
          </div>
        </GlobalOverlayProvider>
      </AuthUserProvider>
      <MyToastContainer />
    </Router>
  );
}

export default App;
