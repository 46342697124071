import { Order } from "../types/order";
import instance from "./http_client";

export function getOrders() {
  return instance
    .get("orders/")
    .then((response) => response.data.map(Order.fromJson));
}

export function cancelOrder(pk: number) {
  return instance
    .patch(`orders/${pk}/update`, { status: "CA" })
    .then((res) => Order.fromJson(res.data));
}

export function submitOrders() {
  return instance.post("/orders/new");
}

export function submitRefillOrder(prescriptionPk: number) {
  return instance.post(`/orders/refills/${prescriptionPk}/new`);
}
