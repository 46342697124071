import {
  ADMIN_FEE_EXPLAINER,
  HANDLING_FEE_EXPLAINER,
  SOURCING_FEE_EXPLAINER,
} from "../../constants";
import { numberWithCommas, round } from "../../utils";
import HelpButton from "../HelpButton/HelpButton";
import styles from "./FeeBreakdown.module.css";

export default function FeeBreakdown(props: {
  tierName: string;
  handlingFee: number;
  adminFee: number;
  quantity: number;
  formulation: string;
  sourcingFee: number;
  multiplier: number | null;
  className?: string;
  title?: string;
  condensed?: boolean;
}) {
  const multiplier = props.multiplier ?? 1;
  const multiplier2 = Math.ceil(multiplier);

  const sourcingFee = multiplier * props.sourcingFee;
  const handlingFee = props.handlingFee;
  const adminFee = multiplier2 * props.adminFee;

  const multiplierDisplay = round(multiplier, 2);

  const handlingFeeLabel = `Handling Fee`;

  const adminFeeLabel = `${
    sourcingFee > 0 && multiplier2 !== 1 ? `${multiplier2} x ` : ""
  } Admin Fee`;

  const sourcingFeeLabel = `${
    sourcingFee > 0 && multiplier !== 1 ? `${multiplierDisplay} x ` : ""
  } ${props.tierName} Sourcing Fee`;

  const total = handlingFee + adminFee + sourcingFee;

  return (
    <div
      className={`${props.className} ${
        props.condensed ? styles.condensed : ""
      }`}
    >
      {props.title && <h2 className={styles.title}>{props.title}</h2>}
      <div className={styles.feeBox}>
        <div className={styles.fee}>
          <p>${numberWithCommas(round(handlingFee, 2))}</p>
          <div className={styles.feeLabel}>
            <p>{handlingFeeLabel}</p>
            <HelpButton message={HANDLING_FEE_EXPLAINER}></HelpButton>
          </div>
        </div>
        <div className={styles.fee}>
          <p>${numberWithCommas(round(adminFee, 2))}</p>
          <div className={styles.feeLabel}>
            <p>{adminFeeLabel}</p>
            <HelpButton message={ADMIN_FEE_EXPLAINER}></HelpButton>
          </div>
        </div>
        <div className={styles.fee}>
          <p className={styles.feeAmount}>
            ${numberWithCommas(round(sourcingFee, 2))}
          </p>
          <div className={styles.feeLabel}>
            <p>{sourcingFeeLabel}</p>
            <HelpButton message={SOURCING_FEE_EXPLAINER}></HelpButton>
          </div>
        </div>
      </div>
      <p className={styles.total}>
        ${numberWithCommas(round(total, 2))} <span>per dispense</span>
      </p>
    </div>
  );
}
