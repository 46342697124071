import Button from "../../../components/Button/Button";
import styles from "./ContactSupportSuccess.module.css";

export default function ContactSupportSuccess(props: { onReset: () => void }) {
  return (
    <div className={styles.body}>
      <div className={styles.submitSuccessBox}>
        <div className={styles.checkBox}>
          <img
            className={styles.check}
            src={require("../../../assets/Check.svg").default}
            alt="help"
          />
        </div>

        <h1>Support request submitted</h1>
        <div className={styles.subtext}>
          <p>
            Thank you for submitting a support request. Your feedback is
            important to us. We will get back to you as soon as possible via
            email.
          </p>
          <Button submit={true} padding="14px 48px" onClick={props.onReset}>
            Submit another
          </Button>
        </div>
      </div>
    </div>
  );
}
