import styles from "./DashboardStates.module.css";

interface DashboardPageNoResultsProps {
  query: string;
}

export default function DashboardPageNoResults(
  props: DashboardPageNoResultsProps
) {
  return <div className={styles.noResults}>No results for {props.query}.</div>;
}
