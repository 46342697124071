import { useEffect, useState } from "react";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import { ApiStatus } from "../../types/api_status";
import { CartItem } from "../../types/cart_item";
import styles from "./Cart.module.css";
import CartList from "./CartList/CartList";
import { clearCart, getCartItems } from "../../api/cart_network_api";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import Button from "../../components/Button/Button";
import CartFooter from "./CartFooter/CartFooter";
import CartReview from "./CartReview/CartReview";
import CartSubmitSuccess from "./CartSubmitSuccess/CartSubmitSuccess";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import { sumPrices } from "../../utils";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";
import { useGlobalOverlay } from "../../providers/GlobalOverlayProvider";
import { useNavigate } from "react-router";
import { useAuth } from "../../providers/AuthUserProvider";

interface CartState {
  cartFetchStatus: ApiStatus;
  cartClearStatus: ApiStatus;
  cartSubmitted: boolean;
  cartItems: CartItem[] | null;
}

export default function Cart() {
  const [state, setState] = useState<CartState>({
    cartFetchStatus: ApiStatus.LOADING,
    cartClearStatus: ApiStatus.INITIAL,
    cartItems: null,
    cartSubmitted: false,
  });

  const navigate = useNavigate();

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const { user, updateUser } = useAuth();

  useEffect(() => {
    getCartItems()
      .then((items) =>
        setState((prev) => ({
          ...prev,
          cartItems: items,
          cartFetchStatus: ApiStatus.SUCCESS,
        }))
      )
      .catch((_) => {
        setState((prev) => ({
          ...prev,
          cartFetchStatus: ApiStatus.FAILURE,
        }));
      });
  }, []);

  const handleClearCart = () => {
    setState((prev) => ({ ...prev, cartClearStatus: ApiStatus.LOADING }));
    clearCart()
      .then(() => {
        setState((prev) => ({
          ...prev,
          cartClearStatus: ApiStatus.SUCCESS,
          cartItems: [],
        }));
        hideGlobalOverlay();
        if (user) {
          handleUpdateUserCartAmount(0);
        }
      })
      .catch((_) =>
        setState((prev) => ({ ...prev, cartClearStatus: ApiStatus.FAILURE }))
      );
  };

  const handleRemoveCartItem = (index: number) => {
    if (state.cartItems === null) {
      return;
    }

    const cartItems = [
      ...state.cartItems.slice(0, index),
      ...state.cartItems.slice(index + 1),
    ];
    setState((prev) => ({
      ...prev,
      cartItems,
    }));

    if (user) {
      handleUpdateUserCartAmount(user?.numCartItems - 1);
    }
  };

  const handleUpdateUserCartAmount = (newAmount: number) => {
    if (!user) {
      return;
    }
    updateUser({ ...user, numCartItems: newAmount });
  };

  return state.cartSubmitted ? (
    <CartSubmitSuccess></CartSubmitSuccess>
  ) : (
    <DashboardPageBody
      title={"Current order"}
      headerSuffix={
        state.cartItems !== null && state.cartItems.length > 0 ? (
          <Button
            padding="8px 24px"
            destructive={true}
            primary={false}
            pill={true}
            onClick={() =>
              setGlobalOverlay(
                <ConfirmationDialog
                  title={"Are you sure you want to clear your current order?"}
                  isDestructive={true}
                  rightLabel="Clear"
                  loading={state.cartClearStatus === ApiStatus.LOADING}
                  onConfirm={handleClearCart}
                  onCancel={hideGlobalOverlay}
                ></ConfirmationDialog>
              )
            }
          >
            Clear
          </Button>
        ) : null
      }
    >
      {state.cartItems !== null ? (
        state.cartItems.length > 0 ? (
          <div className={styles.content}>
            <CartList
              onRemoveItem={handleRemoveCartItem}
              items={state.cartItems}
              // onCheckOut={() =>
              //   setGlobalOverlay(
              //     <CartReview
              //       onExit={(cartSubmitted) => {
              //         hideGlobalOverlay();
              //         setState((prev) => ({
              //           ...prev,
              //           cartSubmitted,
              //         }));
              //       }}
              //       cart={state.cartItems!}
              //     ></CartReview>
              //   )
              // }
              // price={sumPrices(state.cartItems.map((item) => item.totalFees))}
            ></CartList>
            <CartFooter
              onCheckOut={() =>
                setGlobalOverlay(
                  <CartReview
                    onExit={(cartSubmitted) => {
                      hideGlobalOverlay();
                      setState((prev) => ({
                        ...prev,
                        cartSubmitted,
                      }));
                    }}
                    cart={state.cartItems!}
                  ></CartReview>
                )
              }
              price={sumPrices(state.cartItems.map((item) => item.totalFees))}
            ></CartFooter>
          </div>
        ) : (
          <DashboardPageEmptyState
            title={"You haven't add any medications to your order yet"}
            subtitle="Get started by browsing our inventory."
            actionLabel="Go to inventory"
            onClickAction={() => navigate("/inventory")}
          ></DashboardPageEmptyState>
        )
      ) : state.cartFetchStatus === ApiStatus.FAILURE ? (
        <DashboardPageFailState
          errorMessage="Unable to load current order"
          subtitle="Please try reloading."
        ></DashboardPageFailState>
      ) : (
        <DashboardPageLoadState></DashboardPageLoadState>
      )}
    </DashboardPageBody>
  );
}
