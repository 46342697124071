import { deleteCartItem } from "../../../api/cart_network_api";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import { CartItem } from "../../../types/cart_item";
import styles from "./CartList.module.css";
import Person from "../../../assets/PersonGrey.svg";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import Details from "../../../assets/Details.svg";
import FeeBreakdownPopup from "../../../components/FeeBreakdown/FeeBreakdownPopup";
import { numberWithCommas, round } from "../../../utils";

interface CartListItemProps {
  item: CartItem;
  onCartItemDeleted: () => void;
}

export default function CartListItem(props: CartListItemProps) {
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const handleDeleteCartItem = () => {
    deleteCartItem(props.item.pk)
      .then(() => {
        props.onCartItemDeleted();
      })
      .catch(() => {});
  };

  const item = props.item;

  return (
    <tr>
      <td>
        <div className={styles.recipient}>
          <img src={Person} className={styles.personIcon} alt=""></img>
          {item.toPatient?.fullName}
        </div>
      </td>
      <td>
        {item.medication.displayName} {item.medication.strength},{" "}
        {item.quantity} {item.medication.formulation}
      </td>
      <td>{item.remainingRefills}</td>
      <td>
        <div className={styles.fees}>
          ${numberWithCommas(round(item.totalFees, 2))}
          <button
            className={styles.detailsButton}
            onClick={() =>
              setGlobalOverlay(
                <FeeBreakdownPopup
                  subtitle="Fees are applied each time this medication is dispensed."
                  medication={props.item.medication}
                  onClose={hideGlobalOverlay}
                  handlingFee={props.item.medication.pricingTier.handlingFee}
                  sourcingFee={props.item.medication.pricingTier.sourcingFee}
                  adminFee={props.item.medication.pricingTier.adminFee}
                  quantity={props.item.quantity}
                  multiplier={
                    props.item.quantity / props.item.medication.avgQtyPerScript
                  }
                ></FeeBreakdownPopup>
              )
            }
          >
            <img src={Details} alt="Details"></img>
          </button>
        </div>
      </td>
      <td className={styles.actions}>
        <button
          className={styles.trashButton}
          onClick={() => {
            setGlobalOverlay(
              <ConfirmationDialog
                isDestructive={true}
                title={`Are you sure you want to remove ${props.item.medication.displayName}?`}
                onCancel={() => hideGlobalOverlay()}
                onConfirm={() => {
                  hideGlobalOverlay();
                  handleDeleteCartItem();
                }}
                rightLabel="Remove"
              ></ConfirmationDialog>
            );
          }}
        >
          <img
            className={styles.trashIcon}
            src={require("../../../assets/Trash.svg").default}
            alt="Trash"
          ></img>
        </button>
      </td>
    </tr>
  );
}
