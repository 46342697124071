import Card from "../../../components/Card/Card";
import { CartItem } from "../../../types/cart_item";
import styles from "./CartList.module.css";
import CartListItem from "./CartListItem";

interface CartListProps {
  items: CartItem[];
  onRemoveItem: (index: number) => void;
}

export default function CartList(props: CartListProps) {
  return (
    <>
      <Card className={styles.card}>
        <table className={styles.cartList}>
          <tr>
            <th>Recipient</th>
            <th>Medication</th>
            <th>Refills</th>
            <th>Service fees</th>
            <th></th>
          </tr>
          {props.items.map((item, index) => (
            <CartListItem
              item={item}
              onCartItemDeleted={() => props.onRemoveItem(index)}
            ></CartListItem>
          ))}
        </table>
      </Card>
    </>
  );
}
