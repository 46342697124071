import Overlay from "../Overlay/Overlay";
import Card from "../Card/Card";
import styles from "./InfoDialog.module.css";
import Button from "../Button/Button";

interface InfoDialogProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function InfoDialog(props: InfoDialogProps) {
  return (
    <Overlay>
      <Card className={styles.infoDialog}>
        <div className={styles.textWrap}>
          <h3>{props.title}</h3>
          <p>{props.subtitle}</p>
          {props.children}
        </div>
        <Button
          pill={false}
          primary={true}
          padding="10px 24px"
          width="100%"
          onClick={props.onClose}
        >
          Okay
        </Button>
      </Card>
    </Overlay>
  );
}
