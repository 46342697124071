import { Navigate, Route, Routes } from "react-router";
import Cart from "../../pages/Cart/Cart";
import Dashboard from "../../pages/Inventory/Inventory";
import Login from "../../pages/Login/Login";
import OrderHistory from "../../pages/OrderHistory/OrderHistory";
import Signup from "../../pages/Signup/Signup";
import Patients from "../../pages/Patients/Patients";
import ProtectedRoute from "./ProtectedRoute";
import Inventory from "../../pages/Inventory/Inventory";
import Faq from "../../pages/Faq/Faq";
import ContactSupport from "../../pages/ContactSupport/ContactSupport";
import TaskList from "../../pages/TaskList/TaskList";

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/signup" element={<Signup></Signup>}></Route>
      <Route path="/login" element={<Login></Login>}></Route>
      <Route path="/inventory" element={<ProtectedRoute />}>
        <Route path="/inventory" element={<Dashboard></Dashboard>}></Route>
      </Route>
      <Route path="/viewOrder" element={<ProtectedRoute />}>
        <Route path="/viewOrder" element={<Cart></Cart>}></Route>
      </Route>
      <Route path="/orders" element={<ProtectedRoute />}>
        <Route path="/orders" element={<OrderHistory></OrderHistory>}></Route>
      </Route>
      <Route path="/patients" element={<ProtectedRoute />}>
        <Route path="/patients" element={<Patients></Patients>}></Route>
      </Route>
      <Route path="/faq" element={<ProtectedRoute />}>
        <Route path="/faq" element={<Faq></Faq>}></Route>
      </Route>
      <Route path="/support" element={<ProtectedRoute />}>
        <Route
          path="/support"
          element={<ContactSupport></ContactSupport>}
        ></Route>
      </Route>{" "}
      <Route path="/tasks" element={<ProtectedRoute />}>
        <Route path="/tasks" element={<TaskList></TaskList>}></Route>
      </Route>
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<Inventory></Inventory>}></Route>
      </Route>
      <Route path="*" element={<ProtectedRoute />}>
        <Route path="*" element={<Navigate to="/inventory"></Navigate>}></Route>
      </Route>
    </Routes>
  );
}
