import { useState } from "react";
import Button from "../../../components/Button/Button";
import Card from "../../../components/Card/Card";
import Overlay from "../../../components/Overlay/Overlay";
import { ApiStatus } from "../../../types/api_status";
import { CartItem } from "../../../types/cart_item";
import styles from "./CartReview.module.css";
import { numberWithCommas, round } from "../../../utils";
import { useAuth } from "../../../providers/AuthUserProvider";
import { submitOrders } from "../../../api/orders_network_api";

interface CartReviewProps {
  cart: CartItem[];
  onExit: (result: boolean) => void;
}

export default function CartReview(props: CartReviewProps) {
  const [submitStatus, setSubmitStatus] = useState<ApiStatus>(
    ApiStatus.INITIAL
  );

  const { user, updateUser } = useAuth();

  const handleSubmit = () => {
    setSubmitStatus(ApiStatus.LOADING);
    submitOrders()
      .then(() => {
        setSubmitStatus(ApiStatus.SUCCESS);
        props.onExit(true);
        if (user) {
          updateUser({ ...user, numCartItems: 0 });
        }
      })
      .catch((e) => {
        setSubmitStatus(ApiStatus.FAILURE);
      });
  };

  return (
    <Overlay>
      <Card className={styles.cartReview}>
        <button className={styles.exit} onClick={() => props.onExit(false)}>
          <img src={require("../../../assets/X.svg").default} alt="exit" />
        </button>
        <h3 className={styles.title}>Submit order</h3>
        <p className={styles.subtitle}>
          Fees are applied each time a medication is dispensed.
        </p>
        <div className={styles.orders}>
          {props.cart.map((item) => (
            <div key={item.pk} className={styles.order}>
              <div className={styles.orderData}>
                <div className={styles.orderColumn}>
                  <p className={styles.label}>Medication:</p>
                  <div>
                    <p className={styles.name}>
                      {item.medication.displayName} {item.medication.strength}
                    </p>
                    <p className={styles.supply}>
                      {item.quantity} {item.medication.formulation}
                    </p>
                  </div>
                </div>
                <div className={styles.orderColumn}>
                  <p className={styles.label}>Recipient:</p>{" "}
                  <div>
                    <p className={styles.name}>{item.toPatient!.fullName}</p>
                    <p className={styles.address}>
                      {item.toPatient!.displayAddress}
                    </p>
                  </div>
                </div>
              </div>
              <p className={styles.price}>
                ${numberWithCommas(round(item.totalFees, 2))}
              </p>
            </div>
          ))}
        </div>
        <Button
          pill={false}
          width="100%"
          loading={submitStatus === ApiStatus.LOADING}
          onClick={handleSubmit}
        >
          Submit order
        </Button>
      </Card>
    </Overlay>
  );
}
