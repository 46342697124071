import { User } from "../types/user";
import instance from "./http_client";

export function login(username: string, password: string): Promise<void> {
  return instance.post("/auth/login/", {
    username: username,
    password: password,
  });
}

export function logout(): Promise<void> {
  return instance.post("/auth/logout/", {} /* Requires empty body */);
}

export function getAuthUser(): Promise<User> {
  return instance
    .get("/users/me")
    .then((response) => User.fromJson(response.data));
}

export function getCsrf() {
  return instance.get("/auth/csrf/");
}
