import React from "react";
import styles from "./Landing.module.css";
import Button from "../Button/Button";

interface LandingProps {
  buttonText: string;
  loading: boolean;
  secondaryBoxContent: React.ReactNode;
  children: React.ReactNode;
  subheading: string;
  error?: string;
  primaryBoxFooter?: React.ReactNode;
  hideButton?: boolean;
}

export default function Landing(props: LandingProps) {
  return (
    <div className={styles.landing}>
      <div className={styles.content}>
        <div className={`${styles.box} ${styles.mainBox}`}>
          <img
            className={styles.logo}
            src={require("../../assets/Logo.svg").default}
            alt="MediCircle Logo"
          ></img>
          <h2 className={styles.subheading}>{props.subheading}</h2>
          <div className={styles.children}>
            {props.children}
            {props.error && <p className={styles.error}>{props.error}</p>}
          </div>
          {!props.hideButton && (
            <Button pill={false} submit={true} loading={props.loading}>
              {props.buttonText}
            </Button>
          )}
          {props.primaryBoxFooter}
        </div>
        <div className={`${styles.box} ${styles.secondaryBox}`}>
          {props.secondaryBoxContent}
        </div>
      </div>
    </div>
  );
}
