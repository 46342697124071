import ConsentFormLinkPopup from "../../../components/ConsentFormLinkPopup/ConsentFormLinkPopup";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import styles from "./PatientPrescriptions.module.css";
import Link from "../../../assets/Link.svg";
import Info from "../../../assets/Info.svg";
import { Prescription } from "../../../types/prescription";
import { Patient } from "../../../types/patient";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { archivePrescription } from "../../../api/patients_network_api";
import { getPrettyDate } from "../../../utils";
import { toast } from "react-toastify";
import { useState } from "react";
import { submitRefillOrder } from "../../../api/orders_network_api";

export default function PatientPrescriptionItem(props: {
  patient: Patient;
  prescription: Prescription;
}) {
  const [prescription, setPrescription] = useState<Prescription>(
    props.prescription
  );

  const notifySuccess = (text: string) => toast.success(text);
  const notifyError = (text: string) => toast.error(text);

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
  const p = prescription;

  const orderRefill = () => {
    return submitRefillOrder(prescription.pk)
      .then(() => {
        // TODO: Update prescription to reflect new remaining refills, etc.
        notifySuccess("Successfully refilled prescription.");
      })
      .catch(() => {
        notifyError("Unable to refill prescription.");
      });
  };

  const archive = () => {
    return archivePrescription(prescription.pk)
      .then((prescription) => {
        setPrescription(prescription);
        notifySuccess("Prescription archived");
      })
      .catch(() => {
        notifyError("Unable to archive prescription.");
      });
  };

  return (
    <tr className={prescription.archived ? styles.archived : ""}>
      <td className={styles.prescription}>{p.label}</td>
      <td>
        <div>{getPrettyDate(p.dueDate)}</div>
      </td>
      <td>
        <div className={styles.actionCell}>
          {p.remainingRefills}
          {p.remainingRefills > 0 && (
            <button
              className={styles.textButton}
              onClick={() =>
                setGlobalOverlay(
                  <ConfirmationDialog
                    title={"Confirm refill"}
                    subtitle={`Are you sure you would like to refill ${prescription.label} for ${props.patient.fullName}?`}
                    onConfirm={() => {
                      hideGlobalOverlay();
                      orderRefill();
                    }}
                    onCancel={hideGlobalOverlay}
                  />
                )
              }
            >
              Refill
            </button>
          )}
        </div>
      </td>
      <td>
        <div className={styles.actionCell}>
          {p.received ? "Yes" : "No"}
          {!p.received && !p.archived && (
            <button
              className={styles.iconButton}
              onClick={() =>
                setGlobalOverlay(
                  <InfoDialog
                    title="We need this prescription"
                    subtitle="You can send it to us over fax at (832) 202-1304, call us at
              (832) 380-4400, or send it to MediCircle on Surescripts."
                    onClose={hideGlobalOverlay}
                  />
                )
              }
            >
              <img
                src={Info}
                title="Transfer prescription"
                alt="Transfer prescription"
              ></img>
            </button>
          )}
        </div>
      </td>
      <td>
        <div className={styles.actionCell}>
          {p.consentFormSigned ? "Yes" : "No"}
          {!p.consentFormSigned && !p.archived && (
            <button
              className={styles.iconButton}
              onClick={() =>
                setGlobalOverlay(
                  <ConsentFormLinkPopup
                    token={props.patient.consentFormCode}
                    patient={props.patient}
                    onDone={hideGlobalOverlay}
                  />
                )
              }
            >
              <img
                src={Link}
                title="Get patient form link"
                alt="Patient link"
              ></img>
            </button>
          )}
        </div>
      </td>
      <td className={styles.actions}>
        {p.archived ? (
          <p className={styles.archivedLabel}>archived</p>
        ) : (
          <button
            onClick={() =>
              p.received
                ? setGlobalOverlay(
                    <InfoDialog
                      title={"Cannot archive"}
                      subtitle={
                        "This prescription has already been transferred to MediCircle. Please call us if you wish to transfer it elsewhere."
                      }
                      onClose={() => {
                        hideGlobalOverlay();
                      }}
                    />
                  )
                : setGlobalOverlay(
                    <ConfirmationDialog
                      title={"Archive prescription"}
                      subtitle={`Are you sure you would like to archive ${prescription.label} for ${props.patient.fullName}? This will cancel any unshipped orders associated with this prescription and unallocate inventory.`}
                      rightLabel="Archive"
                      onConfirm={() => {
                        hideGlobalOverlay();
                        archive();
                      }}
                      onCancel={hideGlobalOverlay}
                    />
                  )
            }
          >
            archive
          </button>
        )}
      </td>
    </tr>
  );
}
