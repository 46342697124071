import { useState } from "react";
import Card from "../../../components/Card/Card";
import { Patient } from "../../../types/patient";
import styles from "./PatientListItem.module.css";
import { ApiStatus } from "../../../types/api_status";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { deletePatient } from "../../../api/patients_network_api";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import PatientFormOverlay from "../../../components/PatientForm/PatientFormOverlay";
import PatientPrescriptions from "../PatientPrescriptions/PatientPrescriptions";
import Documents from "../../../assets/Documents.svg";
import PatientDocuments from "../PatientDocuments/PatientDocuments";

interface PatientListItemProps {
  patient: Patient;
  onEdit: (patient: Patient) => void;
  onDelete: (patient: Patient) => void;
}

export default function PatientListItem(props: PatientListItemProps) {
  const [deletionStatus, setDeletionStatus] = useState(ApiStatus.INITIAL);

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const handleDelete = () => {
    setDeletionStatus(ApiStatus.LOADING);
    return deletePatient(props.patient.pk)
      .then(() => {
        setDeletionStatus(ApiStatus.SUCCESS);
        props.onDelete(props.patient);
        hideGlobalOverlay();
      })
      .catch((_) => setDeletionStatus(ApiStatus.FAILURE));
  };

  return (
    <Card className={styles.patientListItem}>
      <div className={styles.mainContent}>
        <div className={styles.start}>
          <img
            className={styles.patientIcon}
            src={require("../../../assets/PersonGrey.svg").default}
            alt="Patient icon"
          ></img>
          <div className={styles.infoBox}>
            <p className={styles.name}>{props.patient.fullName}</p>
            <p>{props.patient.displayAddress}</p>
            <div className={styles.contactBox}>
              <p>{props.patient.email}</p>
              <span className={styles.contactDivider}>●</span>
              <p>{props.patient.phoneNumber}</p>
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <button
            onClick={() =>
              setGlobalOverlay(
                <PatientDocuments
                  patient={props.patient}
                  onExit={hideGlobalOverlay}
                />
              )
            }
          >
            <img
              className={styles.patientIcon}
              src={Documents}
              alt="Documents"
            ></img>
          </button>
          <button
            onClick={() =>
              setGlobalOverlay(
                <PatientFormOverlay
                  editingPatient={props.patient}
                  onDone={(updatedPatient) => {
                    if (updatedPatient) {
                      props.onEdit(updatedPatient);
                    }
                    hideGlobalOverlay();
                  }}
                ></PatientFormOverlay>
              )
            }
          >
            <img
              className={styles.patientIcon}
              src={require("../../../assets/Pencil.svg").default}
              alt="Edit"
            ></img>
          </button>
          <button
            onClick={() =>
              setGlobalOverlay(
                <ConfirmationDialog
                  title={`Are you sure you want to delete ${props.patient.fullName}?`}
                  subtitle="This action cannot be undone."
                  rightLabel="Delete"
                  loading={deletionStatus === ApiStatus.LOADING}
                  error={
                    deletionStatus === ApiStatus.FAILURE
                      ? "Unable to delete patient"
                      : ""
                  }
                  isDestructive={true}
                  onConfirm={handleDelete}
                  onCancel={hideGlobalOverlay}
                ></ConfirmationDialog>
              )
            }
          >
            <img
              className={styles.patientIcon}
              src={require("../../../assets/Trash.svg").default}
              alt="Trash"
            ></img>
          </button>
        </div>
      </div>
      {props.patient.prescriptions.length > 0 && (
        <PatientPrescriptions patient={props.patient} />
      )}
    </Card>
  );
}
