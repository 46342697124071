import { Medication } from "../../../types/medication";
import styles from "./InventoryList.module.css";
import InventoryListItem from "./InventoryListItem";

interface InventoryListProps {
  medications: Medication[];
}

export default function InventoryList(props: InventoryListProps) {
  return (
    <div className={styles.inventoryList}>
      {props.medications.map((m, index) => (
        <InventoryListItem
          medication={m}
          index={index}
          key={m.pk}
        ></InventoryListItem>
      ))}
    </div>
  );
}
