export interface RecipientFormJson {
  id: number;
  signed_form: string;
  created_at: string;
}

export default class RecipientForm {
  constructor(public pk: number, public file: string, public createdAt: Date) {}

  static fromJson(data: RecipientFormJson): RecipientForm {
    return new RecipientForm(
      data.id,
      data.signed_form,
      new Date(data.created_at)
    );
  }
}
