import { useEffect, useState } from "react";
import {
  getPatientsWithOutstandingForms,
  getUntransferredPrescriptions,
} from "../../api/patients_network_api";
import { ApiStatus } from "../../types/api_status";
import { Patient } from "../../types/patient";
import { Prescription } from "../../types/prescription";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import styles from "./TaskList.module.css";
import Link from "../../assets/Link.svg";
import Info from "../../assets/Info.svg";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import { useGlobalOverlay } from "../../providers/GlobalOverlayProvider";
import ConsentFormLinkPopup from "../../components/ConsentFormLinkPopup/ConsentFormLinkPopup";
import InfoDialog from "../../components/InfoDialog/InfoDialog";

interface TaskListState {
  apiStatus: ApiStatus;
  patients: Patient[] | null;
  prescriptions: Prescription[] | null;
}

export default function TaskList() {
  const [state, setState] = useState<TaskListState>({
    apiStatus: ApiStatus.LOADING,
    patients: null,
    prescriptions: null,
  });

  const updateState = (data: Partial<TaskListState>) => {
    setState((prev) => ({ ...prev, ...data }));
  };

  useEffect(() => {
    Promise.all([
      getPatientsWithOutstandingForms(),
      getUntransferredPrescriptions(),
    ])
      .then((result) => {
        updateState({
          patients: result[0],
          prescriptions: result[1],
          apiStatus: ApiStatus.SUCCESS,
        });
      })
      .catch(() => {
        updateState({ apiStatus: ApiStatus.FAILURE });
      });
  }, []);

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  return (
    <DashboardPageBody title={"Task list"}>
      {state.patients !== null && state.prescriptions !== null ? (
        state.patients.length + state.prescriptions.length === 0 ? (
          <DashboardPageEmptyState
            title={"Nothing to do right now"}
            subtitle="You're all caught up!"
          ></DashboardPageEmptyState>
        ) : (
          <div className={styles.content}>
            {[
              ...state.patients.map((p) => (
                <div className={styles.task}>
                  <div className={styles.taskBody}>
                    <h2>Unsigned forms</h2>
                    <p>{p.fullName} has unsigned recipient forms.</p>
                  </div>
                  <button
                    className={styles.actionButton}
                    onClick={() =>
                      setGlobalOverlay(
                        <ConsentFormLinkPopup
                          token={p.consentFormCode}
                          patient={p}
                          onDone={hideGlobalOverlay}
                        ></ConsentFormLinkPopup>
                      )
                    }
                  >
                    <img src={Link} alt="Get link"></img>
                  </button>
                </div>
              )),
              ...state.prescriptions.map((p) => (
                <div className={styles.task}>
                  <div className={styles.taskBody}>
                    <h2>Transfer prescription</h2>
                    <p>
                      {p.patientName}'s prescription for{" "}
                      <span className={styles.prescription}>{p.label}</span>{" "}
                      needs to be transferred to MediCircle.
                    </p>
                  </div>{" "}
                  <button
                    className={styles.actionButton}
                    onClick={() =>
                      setGlobalOverlay(
                        <InfoDialog
                          title="We need this prescription"
                          subtitle="You can send it to us over fax at (832) 202-1304, call us at
              (832) 380-4400, or send it to MediCircle on Surescripts."
                          onClose={hideGlobalOverlay}
                        />
                      )
                    }
                  >
                    <img src={Info} alt="Get link"></img>
                  </button>
                </div>
              )),
            ]}
          </div>
        )
      ) : state.apiStatus === ApiStatus.LOADING ? (
        <DashboardPageLoadState></DashboardPageLoadState>
      ) : (
        <DashboardPageFailState
          errorMessage={"Unable to load tasks."}
        ></DashboardPageFailState>
      )}
    </DashboardPageBody>
  );
}
