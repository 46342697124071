import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Input from "../../components/Input/Input";
import styles from "./Signup.module.css";
import { useNavigate } from "react-router";
import Landing from "../../components/Landing/Landing";
import { signup } from "../../api/signup_network_api";
import { useState } from "react";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../constants";
import { useAuth } from "../../providers/AuthUserProvider";
import { useSearchParams } from "react-router-dom";

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  organization?: string;
}

export default function Signup() {
  const [networkError, setNetworkError] = useState("");
  const methods = useForm<IFormInput>({
    mode: "onSubmit",
  });
  const errors = methods.formState.errors;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);
    try {
      await signup(
        searchParams.get("code") ?? "",
        data.firstName,
        data.lastName,
        data.username,
        data.password,
        data.email,
        data.organization
      );
      await auth.login(data.username, data.password);
      navigate("/dashboard");
    } catch (e) {
      setNetworkError("Unable to sign up");
    }
    setLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Landing
          loading={loading}
          error={networkError}
          subheading={
            "Sign up to browse our inventory of recertified medications."
          }
          buttonText={"Sign up"}
          secondaryBoxContent={[
            "Already have an account? ",
            <a href="/login">Log in</a>,
          ]}
        >
          <div className={styles.inputs}>
            <div className={styles.nameBox}>
              <Input
                id={"firstName"}
                name={"firstName"}
                label="First name"
                registerOptions={{ required: true }}
                error={errors.firstName && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
              <Input
                id={"lastName"}
                name={"lastName"}
                label="Last name"
                registerOptions={{ required: true }}
                error={errors.lastName && REQUIRED_FIELD_ERROR_MESSAGE}
              ></Input>
            </div>
            <Input
              type={"email"}
              id={"email"}
              name={"email"}
              label="Email address"
              registerOptions={{ required: true }}
              error={errors.email && REQUIRED_FIELD_ERROR_MESSAGE}
            ></Input>
            <Input
              id={"organization"}
              name={"organization"}
              label="Organization"
            ></Input>
            <Input
              id={"username"}
              name={"username"}
              label="Username"
              registerOptions={{ required: true }}
              error={errors.username && REQUIRED_FIELD_ERROR_MESSAGE}
            ></Input>
            <Input
              type={"password"}
              id={"password"}
              name={"password"}
              label="Password"
              registerOptions={{ required: true }}
              error={errors.password && REQUIRED_FIELD_ERROR_MESSAGE}
            ></Input>
          </div>
        </Landing>
      </form>
    </FormProvider>
  );
}
