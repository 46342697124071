export interface UserJson {
  pk: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  username: string;
  organization: string;
  num_cart_items: number;
}

export class User {
  constructor(
    public pk: number,
    public firstName: string,
    public lastName: string,
    public fullName: string,
    public email: string,
    public username: string,
    public organization: string,
    public numCartItems: number
  ) {}

  static fromJson(data: UserJson): User {
    return new User(
      data.pk,
      data.first_name,
      data.last_name,
      data.full_name,
      data.email,
      data.username,
      data.organization,
      data.num_cart_items
    );
  }
}
