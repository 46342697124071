import { useState, useRef, useEffect } from "react";
import MoreOptions from "../../../components/MoreOptions/MoreOptions";
import styles from "./InventoryExport.module.css";
import Download from "../../../assets/Download.svg";
import OptionItem from "../../../components/MoreOptions/OptionItem";

export default function InventoryExport(props: {
  onDownloadXlsx: () => void;
  onDownloadCsv: () => void;
}) {
  const [showOptions, setShowOptions] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.base}>
      <button
        className={styles.download}
        onClick={() => {
          setShowOptions(true);
        }}
        title="Download inventory"
      >
        <img src={Download} alt="Export"></img>
      </button>
      {showOptions && (
        <MoreOptions ref={ref}>
          <OptionItem
            label={"Download as .xlsx"}
            onClick={() => {
              props.onDownloadXlsx();
              setShowOptions(false);
            }}
          ></OptionItem>
          <OptionItem
            label={"Download as .csv"}
            onClick={() => {
              props.onDownloadCsv();
              setShowOptions(false);
            }}
          ></OptionItem>
        </MoreOptions>
      )}
    </div>
  );
}
