import styles from "./HelpButton.module.css";
import InfoIcon from "../../assets/Info.svg";

export default function HelpButton(props: HelpButtonProps) {
  return (
    <div className={styles.help}>
      <img src={InfoIcon} alt="Info"></img>
      <div className={styles.tooltip}>{props.message}</div>
    </div>
  );
}

interface HelpButtonProps {
  message: string;
}
