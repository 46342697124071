import styles from "./DashboardStates.module.css";
import MoonLoader from "react-spinners/PropagateLoader";

export default function DashboardPageLoadState() {
  return (
    <div className={styles.loadState}>
      <MoonLoader
        color={"rgb(0, 164, 205)"}
        speedMultiplier={0.75}
      ></MoonLoader>
    </div>
  );
}
