import Overlay from "../Overlay/Overlay";
import Card from "../Card/Card";
import styles from "./ConfirmationDialog.module.css";
import Button from "../Button/Button";

interface ConfirmationDialogProps {
  title: string;
  subtitle?: string;
  loading?: boolean;
  error?: string;
  rightLabel?: string;
  leftLabel?: string;
  isDestructive?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  return (
    <Overlay>
      <Card className={styles.patientDeleteConfirmation}>
        <div className={styles.textWrap}>
          <h3>{props.title}</h3>
          <p>{props.subtitle}</p>
        </div>

        {props.error && <p className={styles.deletionError}>{props.error}</p>}
        <div className={styles.deleteActions}>
          <Button
            primary={false}
            pill={false}
            padding="10px 24px"
            width="100%"
            onClick={props.onCancel}
          >
            {props.leftLabel ?? "Cancel"}
          </Button>
          <Button
            pill={false}
            primary={props.isDestructive ? false : true}
            padding="10px 24px"
            width="100%"
            loading={props.loading}
            destructive={props.isDestructive}
            onClick={props.onConfirm}
          >
            {props.rightLabel ?? "Confirm"}
          </Button>
        </div>
      </Card>
    </Overlay>
  );
}
