import { useLocation, useNavigate } from "react-router";
import styles from "./Sidebar.module.css";
import SidebarFooter from "./SidebarFooter";
import SidebarNavItem from "./SidebarNav/SidebarNavItem";
import { useAuth } from "../../providers/AuthUserProvider";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();

  const path = location.pathname;

  if (path === "/login" || path === "/signup" || path === "/passwordReset") {
    return null;
  }

  return (
    <div className={styles.sidebar}>
      <div>
        <img
          className={styles.logo}
          src={require("../../assets/LogoDark.svg").default}
          alt="MediCircle Logo"
        ></img>
        <div className={styles.nav}>
          <SidebarNavItem
            label="Inventory"
            onClick={() => navigate("/inventory")}
            active={path === "/inventory" || path === "/"}
          ></SidebarNavItem>{" "}
          <SidebarNavItem
            label="Task list"
            onClick={() => navigate("/tasks")}
            active={path === "/tasks"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Patient profiles"
            onClick={() => navigate("/patients")}
            active={path === "/patients"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Current order"
            suffix={
              !user || user.numCartItems === 0 ? undefined : (
                <p className={styles.amountInCart}>{user?.numCartItems}</p>
              )
            }
            onClick={() => navigate("/viewOrder")}
            active={path === "/viewOrder"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Order history"
            onClick={() => navigate("/orders")}
            active={path === "/orders"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="FAQ"
            onClick={() => navigate("/faq")}
            active={path === "/faq"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Contact us"
            onClick={() => navigate("/support")}
            active={path === "/support"}
          ></SidebarNavItem>
        </div>
      </div>
      <SidebarFooter></SidebarFooter>
    </div>
  );
}
