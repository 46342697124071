import { Medication } from "../../types/medication";
import Button from "../Button/Button";
import Card from "../Card/Card";
import Overlay from "../Overlay/Overlay";
import FeeBreakdown from "./FeeBreakdown";
import styles from "./FeeBreakdown.module.css";

interface FeeBreakdownPopupProps {
  medication: Medication;
  quantity: number;
  handlingFee: number;
  adminFee: number;
  sourcingFee: number;
  multiplier: number;
  onClose: () => void;
  subtitle?: string;
}

export default function FeeBreakdownPopup(props: FeeBreakdownPopupProps) {
  return (
    <Overlay>
      <Card className={styles.popup}>
        <h2 className={styles.popupTitle}>
          {props.medication.displayName} {props.medication.strength}
        </h2>
        {props.subtitle && <p className={styles.subtitle}>{props.subtitle}</p>}
        <FeeBreakdown
          tierName={props.medication.pricingTier.name}
          handlingFee={props.handlingFee}
          adminFee={props.adminFee}
          sourcingFee={props.sourcingFee}
          multiplier={props.multiplier}
          quantity={props.quantity}
          formulation={props.medication.formulation}
          condensed={false}
        ></FeeBreakdown>
        <div className={styles.buttonWrap}>
          <Button
            primary={true}
            width="100%"
            pill={false}
            onClick={props.onClose}
          >
            Close
          </Button>
        </div>
      </Card>
    </Overlay>
  );
}
