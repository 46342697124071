import { Medication } from "../../../types/medication";
import styles from "./InventoryGrid.module.css";
import InventoryGridItem from "./InventoryGridItem";

interface InventoryListProps {
  medications: Medication[];
}

export default function InventoryGrid(props: InventoryListProps) {
  return (
    <div className={styles.inventoryGrid}>
      {props.medications.map((m) => (
        <InventoryGridItem medication={m} key={m.pk} />
      ))}
    </div>
  );
}
