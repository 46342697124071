import styles from "./DashboardStates.module.css";

interface DashboardPageFailStateProps {
  errorMessage: string;
  subtitle?: string;
}

export default function DashboardPageFailState(
  props: DashboardPageFailStateProps
) {
  return (
    <div className={styles.failState}>
      <h3 className={styles.emptyStateTitle}>{props.errorMessage}</h3>
      <p className={styles.emptyStateSubtitle}>{props.subtitle}</p>
    </div>
  );
}
