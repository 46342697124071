import styles from "./Patients.module.css";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import { useEffect, useState } from "react";
import Search from "../../components/Search/Search";
import { Patient } from "../../types/patient";
import PatientList from "./PatientList/PatientList";
import { getPatients } from "../../api/patients_network_api";
import { ApiStatus } from "../../types/api_status";
import PlusButton from "../../components/Button/PlusButton";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import PatientFormOverlay from "../../components/PatientForm/PatientFormOverlay";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";
import { useGlobalOverlay } from "../../providers/GlobalOverlayProvider";

interface PatientsState {
  patients: Patient[] | null;
  patientsApiStatus: ApiStatus;
  filter: string;
}

const filterPatients = (filter: string, patients: Patient[]) => {
  return patients.filter((p) =>
    p.fullName.toUpperCase().includes(filter.toUpperCase())
  );
};

export default function Patients() {
  const [state, setState] = useState<PatientsState>({
    patients: null,
    patientsApiStatus: ApiStatus.LOADING,
    filter: "",
  });

  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const handlePatientRemoval = (patient: Patient) => {
    if (state.patients === null) {
      return;
    }
    const index = state.patients.findIndex((p) => p.pk === patient.pk);

    if (index !== -1) {
      const patients = [
        ...state.patients.slice(0, index),
        ...state.patients.slice(index + 1),
      ];
      setState((prev) => ({
        ...prev,
        stagedDeletion: undefined,
        patients,
      }));
    }
  };

  const setPatients = (patients: Patient[]) => {
    setState((prev) => ({ ...prev, patients }));
  };

  useEffect(() => {
    getPatients()
      .then((patients) =>
        setState((prev) => ({
          ...prev,
          patients: patients,
          patientsApiStatus: ApiStatus.SUCCESS,
        }))
      )
      .catch((_) =>
        setState((prev) => ({
          ...prev,
          patientsApiStatus: ApiStatus.FAILURE,
        }))
      );
  }, []);

  const handlePatientResult = (patient: Patient) => {
    let patients = [];
    if (!state.patients) {
      patients = [patient];
    } else {
      const patientIndex = state.patients.findIndex((p) => p.pk === patient.pk);
      if (patientIndex !== -1) {
        patients = [
          ...state.patients.slice(0, patientIndex),
          patient,
          ...state.patients.slice(patientIndex + 1),
        ];
      } else {
        patients = [patient, ...state.patients];
      }
    }
    setPatients(patients);
  };

  return (
    <DashboardPageBody
      title={"Your patients"}
      headerSuffix={
        <div className={styles.suffix}>
          <Search
            placeholder="Search patients"
            onChange={(val) => {
              setState((prev) => ({ ...prev, filter: val }));
            }}
          ></Search>
          <PlusButton
            label="Add patient"
            onClick={() =>
              setGlobalOverlay(
                <PatientFormOverlay
                  onDone={(patient) => {
                    if (patient) {
                      handlePatientResult(patient);
                    }
                    hideGlobalOverlay();
                  }}
                ></PatientFormOverlay>
              )
            }
          />
        </div>
      }
    >
      {state.patients !== null ? (
        state.patients.length === 0 ? (
          <DashboardPageEmptyState
            title="You haven't added any patient profiles."
            subtitle={`Get started by clicking the "Add patient" button in the top right.`}
          ></DashboardPageEmptyState>
        ) : filterPatients(state.filter, state.patients).length === 0 ? (
          <p>No results for {state.filter}</p>
        ) : (
          <PatientList
            onDelete={handlePatientRemoval}
            onEdit={handlePatientResult}
            patients={filterPatients(state.filter, state.patients)}
          ></PatientList>
        )
      ) : state.patientsApiStatus === ApiStatus.FAILURE ? (
        <DashboardPageFailState
          errorMessage={"Unable to load patients"}
          subtitle="Please try reloading."
        ></DashboardPageFailState>
      ) : (
        <DashboardPageLoadState></DashboardPageLoadState>
      )}
    </DashboardPageBody>
  );
}
