import styles from "./Search.module.css";

interface SearchProps {
  onChange: (value: string) => void;
  placeholder: string;
  border?: string;
  padding?: string;
  boxShadow?: string;
  width?: string;
  borderRadius?: string;
  fontSize?: string;
  autofocus?: boolean;
}

export default function Search(props: SearchProps) {
  return (
    <div
      className={styles.search}
      style={{
        border: props.border,
        boxShadow: props.boxShadow,
        width: props.width,
        borderRadius: props.borderRadius,
      }}
    >
      <img
        className={styles.searchIcon}
        src={require("../../assets/Search.svg").default}
        alt="Search"
      ></img>
      <input
        autoFocus={props.autofocus}
        style={{ padding: props.padding, fontSize: props.fontSize }}
        onChange={(event) => props.onChange(event.target.value)}
        className={styles.searchInput}
        placeholder={props.placeholder}
      ></input>
    </div>
  );
}
