export interface PrescriptionJson {
  id: number;
  label: string;
  consent_form_signed: boolean;
  total_refills: number;
  remaining_refills: number;
  due_date: string;
  received: boolean;
  quantity: number;
  archived: boolean;
  patient: string;
}

export class Prescription {
  constructor(
    public pk: number,
    public label: string,
    public consentFormSigned: boolean,
    public totalRefills: number,
    public remainingRefills: number,
    public dueDate: Date,
    public received: boolean,
    public quantity: number,
    public archived: boolean,
    public patientName: string
  ) {}

  static fromJson(data: PrescriptionJson): Prescription {
    return new Prescription(
      data.id,
      data.label,
      data.consent_form_signed,
      data.total_refills,
      data.remaining_refills,
      new Date(data.due_date),
      data.received,
      data.quantity,
      data.archived,
      data.patient
    );
  }
}
