import { useFormContext } from "react-hook-form";
import Button from "../Button/Button";
import Input from "../Input/Input";
import styles from "./PatientForm.module.css";

interface PatientFinancialInfoProps {
  onBack: () => void;
}

export default function PatientFinancialInfo(props: PatientFinancialInfoProps) {
  const methods = useFormContext();

  return (
    <div className={styles.financialForm}>
      <h3>Patient financial information</h3>
      <p className={styles.financialSubtitle}>
        The following questions are necessary so that MediCircle can collect
        demographic data as required by government mandate. If these questions
        are left blank, we will ask the patient directly when they sign their
        consent forms.
      </p>
      <div className={styles.financialInputs}>
        <Input
          registerOptions={{ valueAsNumber: true }}
          id={"estimatedIncome"}
          name={"estimatedIncome"}
          label="Estimated patient income"
          type="number"
          min="1"
        ></Input>
        <Input
          registerOptions={{ valueAsNumber: true }}
          id={"householdSize"}
          name={"householdSize"}
          label="Patient household size"
          type="number"
          min="0"
        ></Input>
        <div className={styles.fullSpanInput}>
          <input
            {...methods.register("medicationsUnaffordable")}
            className={styles.signatureCheckbox}
            type="checkbox"
            id="medicationsUnaffordable"
          ></input>
          <label
            className={styles.signatureLabel}
            htmlFor="medicationsUnaffordable"
          >
            The patient has previously reported difficulty affording their
            co-pay, premiums, deductibles or other related health care and
            prescription drug expenses
          </label>
        </div>
        <div className={styles.fullSpanInput}>
          <input
            {...methods.register("medsNotCovered")}
            className={styles.signatureCheckbox}
            type="checkbox"
            id="medsNotCovered"
          ></input>
          <label className={styles.signatureLabel} htmlFor="medsNotCovered">
            The medications for this patient are not on the patient’s formulary
            / covered by insurance
          </label>
        </div>
      </div>

      <div className={styles.financialButtonWrap}>
        <Button primary={false} pill={false} onClick={props.onBack}>
          Back
        </Button>
        <Button
          loading={methods.formState.isSubmitting}
          pill={false}
          submit={true}
          padding="12px 24px"
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
