import { CartItem, VerifyAllocationResult } from "../types/cart_item";
import instance from "./http_client";

export function getCartItems() {
  return instance
    .get("/cart")
    .then((response) => response.data.map(CartItem.fromJson));
}

export function updateCartItemPatient(cartPk: number, patientPk: number) {
  return instance
    .patch(`/cart/${cartPk}/update`, { to_patient: patientPk })
    .then((response) => {
      return CartItem.fromJson(response.data);
    });
}

export function addToCart(
  medicationPk: number,
  patientPk: number,
  quantity: number,
  remainingRefills: number,
  dueDate: string,
  previouslyFilled: boolean,
  daysSupply: number
) {
  const data = {
    patient_pk: patientPk,
    quantity: quantity,
    remaining_refills: remainingRefills,
    due_date: dueDate,
    previously_filled: previouslyFilled,
    days_supply: daysSupply,
  };
  return instance.post(`/cart/${medicationPk}/new`, data);
}

export function deleteCartItem(cartItemPk: number) {
  return instance.delete(`/cart/${cartItemPk}/delete`);
}

export function deleteCartItemByMedication(medicationPk: number) {
  return instance.delete(`/cart/medications/${medicationPk}/delete`);
}

export function clearCart() {
  return instance.delete("/cart/clear");
}

export function verifyCanAllocateCartItem(
  medicationPk: number,
  quantity: number,
  remainingRefills: number,
  dueDate: string,
  previouslyFilled: boolean,
  daysSupply?: number
) {
  return instance
    .post(`/cart/${medicationPk}/verify`, {
      quantity: quantity,
      remaining_refills: remainingRefills,
      due_date: dueDate,
      previously_filled: previouslyFilled,
      days_supply: daysSupply,
    })
    .then((res) => VerifyAllocationResult.fromJson(res.data));
}
