import styles from "./InventoryGrid.module.css";
import AddToCartButton from "../../../components/AddToCartButton/AddToCartButton";
import MedicationDetail from "../../../components/MedicationDetail/MedicationDetail";
import { Medication } from "../../../types/medication";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";

interface InventoryListItemProps {
  medication: Medication;
}

export default function InventoryGridItem(props: InventoryListItemProps) {
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
  const m = props.medication;
  return (
    <div className={styles.inventoryGridItem}>
      <div className={styles.hitTarget}>
        <div className={styles.data}>
          <p className={styles.name}>{props.medication.displayName}</p>
          <p className={styles.strength}>{props.medication.strength}</p>
          <p className={styles.stock}>
            {props.medication.availableStock} {props.medication.formulation}{" "}
            available
          </p>
        </div>
      </div>
      <AddToCartButton
        onClick={() =>
          setGlobalOverlay(
            <MedicationDetail
              medication={m}
              onExit={hideGlobalOverlay}
            ></MedicationDetail>
          )
        }
      ></AddToCartButton>{" "}
    </div>
  );
}
