import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "../types/user";
import {
  getAuthUser,
  getCsrf,
  login,
  logout,
} from "../api/authentication_network_api";
import { ApiStatus } from "../types/api_status";
import AppLoading from "../pages/AppLoading/AppLoading";
import { useNavigate } from "react-router";

interface AuthProviderState {
  user: User | null;
  userFetchStatus: ApiStatus;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextValue {
  user: User | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (user: User) => void;
}

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

const AuthUserProvider = (props: AuthProviderProps) => {
  const navigate = useNavigate();

  const [state, setState] = useState<AuthProviderState>({
    user: null,
    userFetchStatus: ApiStatus.LOADING,
  });

  useEffect(() => {
    getCsrf();
    getAuthUser()
      .then((user) =>
        setState((prev) => ({
          ...prev,
          user,
          userFetchStatus: ApiStatus.SUCCESS,
        }))
      )
      .catch((prev) =>
        setState(() => ({ ...prev, userFetchStatus: ApiStatus.FAILURE }))
      );
  }, []);

  const handleLogin = (username: string, password: string) => {
    return login(username, password)
      .then((_) => getAuthUser())
      .then((user) => {
        setState((prev) => ({ ...prev, user }));
        navigate("/inventory");
      });
  };

  const handleLogout = () => {
    return logout().then(() => {
      setState((prev) => ({ ...prev, user: null }));
      navigate("/login");
    });
  };

  const handleUpdate = (user: User) => {
    setState((prev) => ({ ...prev, user }));
  };

  const value = {
    user: state.user,
    login: handleLogin,
    logout: handleLogout,
    updateUser: handleUpdate,
  };

  return (
    <AuthContext.Provider value={value}>
      {state.userFetchStatus === ApiStatus.LOADING ? (
        <AppLoading></AppLoading>
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthUserProvider;
