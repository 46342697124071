import { useState } from "react";
import { cancelOrder } from "../../../api/orders_network_api";
import Card from "../../../components/Card/Card";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import { Order, OrderStatus } from "../../../types/order";
import { numberWithCommas, round } from "../../../utils";
import ActionRequiredBox from "./ActionRequiredBox";
import styles from "./OrderHistoryList.module.css";
import { toast } from "react-toastify";

interface OrdereHistoryItemProps {
  order: Order;
  onViewConsentCode: () => void;
}

export default function OrderHistoryItem(props: OrdereHistoryItemProps) {
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const notifySuccess = (text: string) => toast.success(text);
  const notifyError = (text: string) => toast.error(text);

  const [order, setOrder] = useState<Order>(props.order);

  const cancel = () => {
    return cancelOrder(props.order.pk)
      .then((order) => {
        notifySuccess("Order sucessfully canceled");
        setOrder(order);
      })
      .catch(() => {
        notifyError("Unable to cancel order");
      });
  };

  return (
    <Card className={styles.orderHistoryItem}>
      <div className={styles.header}>
        <div>
          <h3 className={styles.status}>
            {order.status === OrderStatus.Delivered
              ? "Delivered"
              : order.status === OrderStatus.Shipped
              ? "Shipped"
              : order.status === OrderStatus.ActionRequired
              ? "Action required"
              : order.status === OrderStatus.Canceled
              ? "Canceled"
              : "Preparing for shipment"}
          </h3>
          {props.order.trackingNumber && (
            <p className={styles.trackingNumber}>
              Tracking number: {props.order.trackingNumber}
            </p>
          )}
        </div>
        {order.status === OrderStatus.PreparingForShipment && (
          <button
            onClick={() =>
              setGlobalOverlay(
                <ConfirmationDialog
                  title={"Are you sure you want to cancel this order?"}
                  leftLabel="Cancel"
                  onConfirm={() => {
                    hideGlobalOverlay();
                    cancel();
                  }}
                  onCancel={hideGlobalOverlay}
                ></ConfirmationDialog>
              )
            }
            className={styles.cancel}
          >
            Cancel order
          </button>
        )}
      </div>

      {order.status === OrderStatus.ActionRequired && (
        <ActionRequiredBox order={order}></ActionRequiredBox>
      )}
      <div className={styles.data}>
        <div className={styles.orderPlaced}>
          <p className={styles.label}>Order placed</p>
          <p className={styles.value}>{order.getPrettyDate()}</p>
        </div>
        <div className={styles.shipTo}>
          <p className={styles.label}>Ship to</p>
          <p className={styles.value}>{order.patient.fullName}</p>
          <p className={styles.value}> {order.patient.displayAddress}</p>
        </div>
        <div className={styles.medications}>
          <p className={styles.label}>Medication(s)</p>
          <div className={styles.orderItems}>
            {order.prescriptions.map((p) => (
              <div className={styles.orderItem}>
                <p>{p}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.total}>
          <p className={styles.label}>Total fees</p>
          <p className={styles.value}>
            ${numberWithCommas(round(order.totalFees, 2))}
          </p>
        </div>
      </div>
    </Card>
  );
}
