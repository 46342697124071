import Button from "../../../components/Button/Button";
import styles from "./CartFooter.module.css";

interface CartFooterProps {
  price: number;
  onCheckOut: () => void;
}

export default function CartFooter(props: CartFooterProps) {
  return (
    <div className={styles.footer}>
      <Button padding="16px 64px" onClick={props.onCheckOut}>
        Review order
      </Button>
    </div>
  );
}
