import { useAuth } from "../../providers/AuthUserProvider";
import styles from "./Sidebar.module.css";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { useGlobalOverlay } from "../../providers/GlobalOverlayProvider";

export default function SidebarFooter() {
  const auth = useAuth();
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  return (
    <div className={styles.footer}>
      <svg
        className={styles.profileIcon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="#cbd7d8"
        viewBox="0 0 20 20"
      >
        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
      </svg>
      <div>
        <p className={styles.footerName}>{auth.user?.fullName}</p>
        <a
          onClick={() =>
            setGlobalOverlay(
              <ConfirmationDialog
                title={"Are you sure you want to log out?"}
                rightLabel="Log out"
                isDestructive={true}
                onCancel={() => hideGlobalOverlay()}
                onConfirm={() => {
                  auth.logout();
                  hideGlobalOverlay();
                }}
              />
            )
          }
          href="#/"
        >
          Log out
        </a>
      </div>
    </div>
  );
}
