import styles from "./MoreOptions.module.css";

interface OptionItemProps {
  label: string;
  onClick: () => void;
}

export default function OptionItem(props: OptionItemProps) {
  return (
    <button className={styles.option} onClick={props.onClick}>
      {props.label}
    </button>
  );
}
