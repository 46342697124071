import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Input from "../../components/Input/Input";
import styles from "./Login.module.css";
import Landing from "../../components/Landing/Landing";
import { useState } from "react";
import { useAuth } from "../../providers/AuthUserProvider";

interface IFormInput {
  username: string;
  password: string;
}

export default function Login() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const methods = useForm<IFormInput>({
    mode: "onSubmit",
  });

  const auth = useAuth();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);
    try {
      await auth.login(data.username, data.password);
    } catch (e) {
      setError("Unable to log in");
    }
    setLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Landing
          primaryBoxFooter={
            <a
              className={styles.forgotPassword}
              target="_blank"
              rel="noreferrer"
              href="https://api.medicirclerx.com/auth/password/reset"
            >
              Forgot password?
            </a>
          }
          subheading={
            "Login to browse our inventory of recertified medications."
          }
          error={error}
          buttonText={"Log in"}
          loading={loading}
          secondaryBoxContent={[
            "Don't have an account? ",
            <a href="/signup">Sign up</a>,
          ]}
        >
          <div className={styles.inputs}>
            <Input id={"username"} name={"username"} label="Username"></Input>
            <Input
              type={"password"}
              id={"password"}
              name={"password"}
              label="Password"
            ></Input>
          </div>
        </Landing>
      </form>
    </FormProvider>
  );
}
