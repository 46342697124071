import { useEffect, useState } from "react";
import styles from "./Inventory.module.css";
import { Medication } from "../../types/medication";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import Search from "../../components/Search/Search";
import { ApiStatus } from "../../types/api_status";
import {
  exportAsCsv,
  exportAsXlsx,
  getInventory,
} from "../../api/inventory_network_api";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import InventoryList from "./InventoryList/InventoryList";
import DashboardPageNoResults from "../../components/DashboardPageBody/DashboardStates/DashboardPageNoResults";
import InventoryGrid from "./InventoryGrid/InventoryGrid";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";
import InventoryExport from "./InventoryExport/InventoryExport";

interface InventoryState {
  filter: string;
  medications: Medication[] | null;
  medicationsApiStatus: ApiStatus;
  gridView: boolean;
}

const filterMedications = (filter: string, medications: Medication[]) => {
  if (filter === "") {
    return medications;
  }

  const pattern = filter.toUpperCase();

  return medications.filter(
    (m) =>
      `${m.brandName} ${m.strength}`.toUpperCase().includes(pattern) ||
      m.genericName.toUpperCase().includes(pattern)
  );
};

export default function Inventory() {
  const [state, setState] = useState<InventoryState>({
    filter: "",
    medications: null,
    medicationsApiStatus: ApiStatus.LOADING,
    gridView: false,
  });

  const setFilter = (filter: string) => {
    setState((prev) => ({ ...prev, filter }));
  };

  useEffect(() => {
    getInventory()
      .then((medications) =>
        setState((prev) => ({
          ...prev,
          medications,
          medicationsApiStatus: ApiStatus.SUCCESS,
        }))
      )
      .catch((_) =>
        setState((prev) => ({
          ...prev,
          medicationsApiStatus: ApiStatus.FAILURE,
        }))
      );
  }, []);

  return (
    <DashboardPageBody title="Inventory">
      <div className={styles.body}>
        <div className={styles.suffix}>
          <Search
            width="100%"
            padding="16px"
            placeholder="Search available medications"
            onChange={setFilter}
            borderRadius="500px"
            fontSize="18px"
            autofocus={true}
          ></Search>
          <InventoryExport
            onDownloadXlsx={exportAsXlsx}
            onDownloadCsv={exportAsCsv}
          ></InventoryExport>
        </div>
        {state.medications !== null ? (
          <div className={styles.content}>
            {filterMedications(state.filter, state.medications).length === 0 ? (
              state.filter.length > 0 ? (
                <DashboardPageNoResults
                  query={state.filter}
                ></DashboardPageNoResults>
              ) : (
                <DashboardPageEmptyState
                  title={"Nothing to display right now"}
                  subtitle="Please check again later."
                ></DashboardPageEmptyState>
              )
            ) : state.gridView ? (
              <InventoryGrid
                medications={filterMedications(state.filter, state.medications)}
              ></InventoryGrid>
            ) : (
              <InventoryList
                medications={filterMedications(state.filter, state.medications)}
              ></InventoryList>
            )}
          </div>
        ) : state.medicationsApiStatus === ApiStatus.FAILURE ? (
          <DashboardPageFailState
            errorMessage="Unable to load inventory"
            subtitle="Please try reloading."
          ></DashboardPageFailState>
        ) : (
          <DashboardPageLoadState></DashboardPageLoadState>
        )}
      </div>
    </DashboardPageBody>
  );
}
