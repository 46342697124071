import styles from "./DashboardPageBody.module.css";

interface DashboardPageBodyProps {
  children: React.ReactNode;
  title: string;
  headerSuffix?: React.ReactNode;
}

export default function DashboardPageBody(props: DashboardPageBodyProps) {
  return (
    <div className={styles.dashboardPageBody}>
      <div className={styles.headerBox}>
        <h2 className={styles.title}>{props.title}</h2>
        {props.headerSuffix}
      </div>
      {props.children}
    </div>
  );
}
