import { Medication, MedicationJson } from "./medication";
import { Patient, PatientJson } from "./patient";

export interface CartItemJson {
  id: number;
  medication: MedicationJson;
  created_at: string;
  quantity: number;
  to_patient?: PatientJson;
  total_fees: number;
  remaining_refills: number;
}

export class CartItem {
  constructor(
    public pk: number,
    public medication: Medication,
    public createdAt: Date,
    public quantity: number,
    public totalFees: number,
    public remainingRefills: number,
    public toPatient?: Patient
  ) {}

  static fromJson(data: CartItemJson): CartItem {
    return new CartItem(
      data.id,
      Medication.fromJson(data.medication),
      new Date(data.created_at),
      data.quantity,
      data.total_fees,
      data.remaining_refills,
      data.to_patient ? Patient.fromJson(data.to_patient) : undefined
    );
  }
}

export interface VerifyAllocationResultJson {
  can_allocate: boolean;
  fills_available: number;
}

export class VerifyAllocationResult {
  constructor(public canAllocate: boolean, public fillsAvailable: number) {}

  static fromJson(data: VerifyAllocationResultJson) {
    return new VerifyAllocationResult(data.can_allocate, data.fills_available);
  }
}
