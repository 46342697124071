import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

interface GlobalOverlayValue {
  setGlobalOverlay: (component: ReactNode) => void;
  hideGlobalOverlay: () => void;
}

const GlobalOverlayContext = createContext<GlobalOverlayValue>(
  {} as GlobalOverlayValue
);

export default function GlobalOverlayProvider(props: { children: ReactNode }) {
  const [overlay, setOverlay] = useState<ReactNode | null>(null);
  const path = useLocation().pathname;

  const handleSetOverlay = (component: ReactNode) => {
    setOverlay(component);
  };
  const handleHideOverlay = () => {
    setOverlay(undefined);
  };

  // Hide overlay when page changes
  useEffect(handleHideOverlay, [path]);

  const value = {
    setGlobalOverlay: handleSetOverlay,
    hideGlobalOverlay: handleHideOverlay,
  };

  return (
    <GlobalOverlayContext.Provider value={value}>
      {props.children}
      {overlay}
    </GlobalOverlayContext.Provider>
  );
}

export const useGlobalOverlay = () => {
  return useContext(GlobalOverlayContext);
};
