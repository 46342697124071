import instance from "./http_client";

export function signup(
  inviteCode: string,
  firstName: string,
  lastName: string,
  username: string,
  password: string,
  email: string,
  organization?: string | undefined
): Promise<void> {
  return instance.post(`users/new?code=${inviteCode}`, {
    first_name: firstName,
    last_name: lastName,
    username: username,
    password: password,
    email: email,
    organization: organization,
  });
}
